import { Component, OnInit } from '@angular/core';
import { CreditBucket } from '@pepconnect/features/teams/models/team-credit-bucket.model';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { distinctUntilChanged, take } from 'rxjs';

@Component({
  selector: 'app-team-points-history',
  templateUrl: './team-points-history.component.html',
  styles: [
  ]
})
export class TeamPointsHistoryComponent implements OnInit {
  availableBuckets: CreditBucket[];
  futureBuckets: CreditBucket[];
  expiredBuckets: CreditBucket[];

  constructor(private teamService: TeamsService, private teamStore: TeamStore) { }

  ngOnInit(): void {
    this.teamStore.$teamMember
      .pipe(distinctUntilChanged((prev, curr) => prev?.team?.creditCount === curr?.team?.creditCount))
        .subscribe((teamMember) => {
          this.fetchCredits(teamMember.team.teamID);
      });
    }

  fetchCredits(teamId: string) {
    this.teamService.fetchTeamCredits(teamId)
      .pipe(take(1))
      .subscribe((creditBuckets) => {
        this.availableBuckets = creditBuckets
          .filter((b) => b.available)
          .sort((a: CreditBucket, b: CreditBucket) => {
            return Date.parse(a.expirationDate) - Date.parse(b.expirationDate);
          });

        this.futureBuckets = creditBuckets
          .filter((b) => b.active && !b.available && !b.expired && b.credits > 0)
          .sort((a: CreditBucket, b: CreditBucket) => {
            return Date.parse(a.startDate) - Date.parse(b.startDate);
          });

        this.expiredBuckets = creditBuckets
          .filter((b) => b.expired)
          .sort((a: CreditBucket, b: CreditBucket) => {
            return Date.parse(a.expirationDate) - Date.parse(b.expirationDate);
          });
      });
  }

}

