<app-collection-item [routerLink]="item | link:'page'" [compact]="true">
    <div class="row">
        <div class="col-12 col-md-9 display-flex">
            <div><span class="icon-check color-green-dark font-size-xsmall"></span></div>
            <div>
                <h4 class="font-size-xsmall display-inline" [innerHTML]="item.title"></h4>
                <div class="color-black-40 font-size-xsmall">{{item.status.lastAccessed + 'Z' | date: "longDate"}}</div>
            </div>

        </div>
        <div class="col-12 col-md-3 display-flex justify-content-center justify-content-md-end cert-button-container">
            <app-certificate-button [item]="item" [hidePaymentOwed]="true" [teamId]="teamId"></app-certificate-button>
        </div>
    </div>
</app-collection-item>
