import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { LinkRel } from "@pepconnect/enums/link-rel.enum";
import { BaseSmartComponent } from "@pepconnect/shared/base/base-smart-component/base-smart.component";
import { AppState } from "@pepconnect/state/index";
import { TeamMemberStatus } from "@pepconnect/teams/enums/teammember-status.enum";
import { TeamMember } from "@pepconnect/teams/models/team-member.model";
import { TeamMemberHelpersService } from "@pepconnect/teams/services/team-member-helpers.service";

/** Base Team Member component with common helper functions and properties for conformity.
 *  Note: For Owner Action or Member Action helpers, see TeamMemberActionsService
 * 
 * Left as abstract because subcomponents all have different ways to get member
 * and teamMember and they need to be able to hook setters and getters. This allows
 * the lazy implementation that just declares them as properties. But also lets
 * subcomponents be reactive when they are passed as @Input values.
 */
@Component({ template: '' })
export abstract class BaseTeamMemberComponent extends BaseSmartComponent {

  /** Refers to the iterated over member.  Such as the member in the card. */
  abstract get member(): TeamMember;

  /** Refers to the currently logged in teamMember.  Such as the person viewing the member details. */
  abstract get teamMember(): TeamMember;
  teamMemberHelpers = new TeamMemberHelpersService();
  statuses = TeamMemberStatus;

  constructor(protected store: Store<AppState>) {
    super(store);
  }

  get memberFirstName() {
    return this.teamMemberHelpers.getMemberFirstName(this.member);
  }
  get memberLastName() {
    return this.teamMemberHelpers.getMemberLastName(this.member);
  }
  get memberName() {
    return this.teamMemberHelpers.getMemberName(this.member);
  }

  get memberEmail() {
    return this.teamMemberHelpers.getMemberEmail(this.member);
  }

  get memberAvatar() {
    return this.teamMemberHelpers.getMemberAvatar(this.member);
  }

  get memberJoinOrInvitedLabel() {
    return this.member.isPending ? 'TEXT_INVITEDON' : 'TEXT_JOINED';
  }

  get memberJoinOrInvitedDate() {
    return this.member.isPending ? this.member.inviteDate : this.member.joinDate;
  }

  get memberPageLink() {
    return this.member.links.find(l => l.rel === LinkRel.page);
  }

}
