import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamMemberActionsService } from '@pepconnect/features/teams/services/team-member-actions.service';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { Link } from '@pepconnect/models/general/link.model';
import { UserAvatar } from '@pepconnect/models/user/user-avatar.model';
import { WINDOW } from '@pepconnect/providers/window-provider';
import { UserAvatarSize } from '@pepconnect/shared/presentation/profile/user-avatar/user-avatar.component';
import { AppState } from '@pepconnect/state/index';
import { BaseTeamMemberComponent } from '../../base/base-team-member.component';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';

@Component({
  selector: 'app-member-avatar-card',
  templateUrl: './member-avatar-card.component.html'
})
export class MemberAvatarCardComponent extends BaseTeamMemberComponent implements OnChanges {

  constructor(
    protected store: Store<AppState>,
    private teamMemberActionService: TeamMemberActionsService,
    private router: Router,
    private teamStore: TeamStore,
    @Inject(WINDOW) private window: Window
  ) { super(store); }


  @Input() teamMember: TeamMember; // The team member (owner) performing actions
  @Input() member: TeamMember // The team member represented in the card
  @Input() size: UserAvatarSize = 'medium';
  userAvatar: UserAvatar;
  actions: Array<Link>;


  ngOnChanges(changes: SimpleChanges) {
    if(changes.member || changes.teamMember) {
      this.setActions();
    }
  }

  private setActions() {
    if(this.member && this.teamMember) {
      this.actions = this.teamMemberActionService.createActionList(this.member, this.teamMember);
    }
  }

  gotoMemberDetail() {
    if (this.teamMember.isAdmin) {
      const target = this.memberPageLink.href;
      this.router.navigateByUrl(target);
    }
  }

  emailMember() {
    return this.window.open(`mailto:${this.memberEmail}`, '_blank');
  }

  dispatch(action: Link) {
    this.teamMemberActionService.dispatch(action, this.member, this.teamMember, true)?.subscribe((res) => {
      if (res) {
        this.teamStore.loadTeamMembers(this.teamMember.team.teamID);
        if (action.rel === LinkRel.ownerPromote || LinkRel.ownerDemote) {
          /** this will trigger the store to change teamMember - that will cascade up
           * to the parent component who will then change the @Input teamMember above
           * and the actions should be updated accordingly
           */
          this.teamStore.loadSelf(this.teamMember.team.friendlyURL);
        }
      }
    });
  }

}
