<app-collection-item *ngIf="loading">
    <app-spinner></app-spinner>
</app-collection-item>
<app-collection-item class="clickable-collection-item" *ngIf="!loading">
    <div class="display-flex justify-content-between member-plan-mini-card" (click)="goToPlan()" >
        <div class="display-flex flex-column justify-content-center">
            <h4 class="font-size-small margin-bottom-0 margin-top-0">{{ learningPlan?.name }}</h4>
            <small>{{ 'TEXT_ASSIGNEDMEMBERS' | translate}}: {{assignedMembers()}}</small>
        </div>
        <app-plans-pie-chart [status]="learningPlan.status"></app-plans-pie-chart>
    </div>
</app-collection-item>
