import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranscriptItem } from '@pepconnect/models/transcript/transcript-item.model';

@Component({
  selector: 'app-member-transcript-card',
  templateUrl: './member-transcript-card.component.html',
  styleUrls: ['./member-transcript-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberTranscriptCardComponent {
  @Input() item: TranscriptItem;
  @Input() teamId: string;

}
