import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { AppState } from '@pepconnect/state/index';
import { BaseTeamsComponent } from '../../../base/base-teams.component';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { NotificationService } from '@pepconnect/services/notification.service';


@Component({
  selector: 'app-team-privacy-toggle',
  templateUrl: './team-privacy-toggle.component.html',
  styles: [
  ]
})
export class TeamPrivacyToggleComponent extends BaseTeamsComponent implements OnInit {
  processing = false;
  constructor(
    private teamService: TeamsService,
    private teamStore: TeamStore,
    protected store: Store<AppState>,
    private notificationService: NotificationService
    ) { super(store); }

  ngOnInit(): void {
    this.teamStore.$teamMember.subscribe((teamMember) => {
      if (teamMember) {
        this.teamMember = teamMember;
      }
    });
  }

  // IMPORTANT - A yes answer needs to have its polarity flip due to the way the question is asked and how the value is actually stored.
  // We ask if they want to make the group private, but the data prop is called allowOpenAccess.
  // To accomodate this, we use a false value for an answer of yes and vis-a-versa in the toggle component (see html)
  togglePrivacy($event: any) {
    this.processing = true;
    this.teamService.setAllowPublic(this.teamMember.team.teamID, $event)
    .subscribe({
      next: () => this.processing = false,
      error: (error) => this.notificationService.showError(error.Message)
    });
  }

}
