<div class="team-subscription-container" *ngIf="member?.showSubscriptions && member?.isMemberActive">
    <div class="card-title">
        <h5 class="margin-top-0 font-family-headline">{{ header }}</h5>
    </div>
    <!-- Team Active Sub cards -->
    <div class="sub-cards" *ngIf="!isExpiredSubSection && !isUpcomingSubSection">
        <div *ngIf="teamSubscriptions?.length === 0 && isSubscriptionBrowsePage" class="no-active-subs">
            {{'TEXT_NO_ACTIVE_SUBSCRIPTIONS' | translate}}
        </div>
        <ng-container *ngIf="teamSubscriptions?.length > 0">
        <app-team-subscription-item *ngFor="let subscription of teamSubscriptions" [teamSubscription]="subscription"
            [teamMember]="member" [isSubscriptionBrowsePage]="isSubscriptionBrowsePage" [isExpiredSubSection]="false">
        </app-team-subscription-item>
        </ng-container>
    </div>
    <!-- Team Upcoming Sub cards -->
    <div *ngIf="isUpcomingSubSection">
        <app-team-subscription-item *ngFor="let upcomingSubs of teamUpcomingSubscriptions" [teamSubscription]="upcomingSubs" [teamMember]="member"
            [isSubscriptionBrowsePage]="true" [isExpiredSubSection]="false" [isUpcomingSubSection]="true"></app-team-subscription-item>

    </div>
    <!-- Team Expired Sub cards -->
    <div class="sub-cards" *ngIf="teamExpiredSubscriptions?.length > 0 && isExpiredSubSection">
        <app-team-subscription-item *ngFor="let expiredSubscription of teamExpiredSubscriptions"
            [teamSubscription]="expiredSubscription" [teamMember]="member"
            [isSubscriptionBrowsePage]="isSubscriptionBrowsePage" [isExpiredSubSection]="isExpiredSubSection">
        </app-team-subscription-item>
    </div>

    <div *ngIf="member?.isAdmin && !isSubscriptionBrowsePage && !isExpiredSubSection && !isUpcomingSubSection" class="find-sub">
        <div class="icon-search" (click)="redirectToSubPage()">{{ 'LINK_FINDSUBSCRIPTIONS' | translate}}</div>
    </div>
</div>
