<ng-container *ngIf="teamMember.isAdmin">
    <app-toggle-button-group (onChange)="togglePrivacy($event)" [selected]="teamMember.team.allowOpenAccess" [label]="'LABEL_MAKEGROUPPRIVATE' | translate">
        <!-- SEE NOTE in ts file re: why the value is false for 'yes'-->
        <app-toggle-button [value]="false" [disabled]="processing">
            {{ 'LABEL_YES' | translate }}
        </app-toggle-button>
        <app-toggle-button [value]="true" [disabled]="processing">
            {{ 'LABEL_NO' | translate }}
        </app-toggle-button>
    </app-toggle-button-group>
</ng-container>
