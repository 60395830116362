import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, shareReplay, switchMap, takeUntil } from 'rxjs';
import { CurrencyFormatRequest } from '@pepconnect/dtos/country-format-currency.dto';
import { CartService } from '@pepconnect/features/checkout/services/cart.service';
import { Team } from '@pepconnect/features/teams/models/team.model';
import { AnalyticsService } from '@pepconnect/services/analytics.service';
import { CountryService } from '@pepconnect/services/country.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AppState } from '@pepconnect/state/index';

/** PointPicker - allows a user to select an amount of points to purchase.
 *
 * @param team - the team that the user is purchasing points for
 * @param teamMemberId - the team member id of the user purchasing points
 * @param redirect - the url to redirect to after the purchase is complete
 */

/** in ng1, this is a standalone component.
 * Siemens\PEP-Site\src\ng1\app\services\components\atm\atm.component.html
 * Siemens\PEP-Site\src\ng1\app\services\components\atm\atm.component.js
*/

@Component({
  selector: 'app-purchase-point-picker',
  templateUrl: './purchase-point-picker.component.html',
  styleUrls: ['./purchase-point-picker.component.scss']
})
export class PurchasePointPickerComponent extends BaseSmartComponent implements OnInit {

  @Input() team: Team;
  @Input() teamMemberId: string;
  @Input() redirect: string;

  @Output() onCancel = new EventEmitter();

  pointPickerForm: FormGroup;
  formattedCurrency: Observable<string>;

  constructor(protected store: Store<AppState>,
              private fb: FormBuilder, private countryService: CountryService,
              private analyticsService: AnalyticsService,
              private cartService: CartService,
              private router: Router) {
              super(store);
            }

  /** point picker labels for form */
  pointOptions = [
    { value: 100 },
    { value: 500 },
    { value: 1000 },
    { value: 1500 },
    { value: 2000 },
    { label: 'LABEL_OTHER', value: -1 }
  ]

  ngOnInit(): void {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (response) => {
        this.authState = response;
      }
    });

    this.setUpForm();
  }

  setUpForm() {
    this.pointPickerForm = this.fb.group({
      selectedPoints: this.fb.control(null, [Validators.required]),
      actualPoints: this.fb.control(null, [Validators.required, Validators.min(1), Validators.max(5000)])
    });

    this.formattedCurrency = this.pointPickerForm.controls.actualPoints.valueChanges.pipe(switchMap(points => {
      if (!points) {
        return of('');
      }

      const request: CurrencyFormatRequest = {
        amount: points,
        countryCode: this.team.country.code
      };
      return this.countryService.formatCurrency(request);
    }), shareReplay(1));

    /** 
     * the label is ngIf so we just subscribe to make sure we always have a running subscription
     * which works with the shareReplay so when the label subscribes, it just replays the latest
     * for the html | async call
     */
    this.formattedCurrency.pipe(takeUntil(this.ngUnsubscribe)).subscribe();
  }

  get f() {
    return this.pointPickerForm?.controls;
  }

  checkout() {
    this.pointPickerForm.markAsTouched();
    if (!this.pointPickerForm.valid) {
      return;
    }

    const orderItem = { teamMemberID: this.teamMemberId, quantity: this.f.actualPoints.value, itemType: 10 };
    this.analyticsService.trackPurchaseData({
      action: 'cart.checkout',
      data: {
        order: {
          type: 'credit card'
        },
        products: {
          id: `Buying points for Team: ${orderItem.teamMemberID}`,
          name: `Points: ${orderItem.quantity}`
        }
      }
    });

    if (this.redirect) {
      this.cartService.initialize(orderItem, this.redirect);
    }
    else {
      this.cartService.initialize(orderItem, this.router.url);
    }
    // redirect to checkout
    this.cartService.checkout();
  }

  selectPoints(points: number) {
    this.pointPickerForm.get('selectedPoints').setValue(points);
    if (points > 0) {
      this.pointPickerForm.get('actualPoints').setValue(points);
    } else {
      this.pointPickerForm.get('actualPoints').setValue(null);
    }

    this.pointPickerForm.markAsUntouched();
  }

  showInputFieldError(): boolean {
    return this.pointPickerForm.touched && (this.f.actualPoints.hasError('min') || this.f.actualPoints.hasError('max'));
  }

  showRequiredMessage(): boolean {
    return this.pointPickerForm.touched && this.f.actualPoints.hasError('required');
  }

  showCostLabels(): boolean {
    return this.f.actualPoints.valid && this.f.actualPoints.value > 0;
  }

  cancel() {
    this.onCancel.emit();
  }

}
