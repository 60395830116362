<div class="display-flex justify-content-center" *ngIf="display === 'grid'; else list">
    <div *ngIf="teamMembers && teamMember" class="member-list-grid">
        <app-member-avatar-card [member]="member" *ngFor="let member of teamMembers; trackBy: trackByMember" [size]="size" [teamMember]="teamMember"></app-member-avatar-card>
        <a *ngIf="hasMore" [routerLink]="membersPage" class="display-flex justify-content-center align-items-center">
            <span class="icon-plus margin-right-0"></span>
            <span class="more-text">{{ moreCount }} {{'TEXT_MORE' | translate}}</span>
        </a>
    </div>
</div>

<ng-template #list>
    <div *ngIf="teamMembers && teamMember" class="display-flex justify-content-center list-view align-items-center">
        <app-member-avatar-card [member]="member" *ngFor="let member of teamMembers; trackBy: trackByMember" [size]="size" [teamMember]="teamMember"></app-member-avatar-card>
        <a *ngIf="hasMore" [routerLink]="membersPage" class="padding-top-half">
            <div *ngIf="hasMore" [routerLink]="membersPage" class="more-button">
                <span class="icon-plus margin-right-0"></span>
                <span class="more-text">{{'TEXT_MORE' | translate}}</span>
            </div>
        </a>
    </div>
</ng-template>
