import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { Link } from '@pepconnect/models/general/link.model';
import { LearningPlan } from '@pepconnect/models/plan/learning-plan.model';
import { LinksService } from '@pepconnect/services/links.service';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { AppState } from '@pepconnect/state/index';
import { of, take } from 'rxjs';
import { TeamFile } from '../../../../models/team-library.model';
import { TeamLearningPlanService } from '../../../../services/team-learning-plan.service';
import { BaseTeamMemberComponent } from '../../../base/base-team-member.component';
import { Team } from '../../../../models/team.model';
import { AnalyticsService } from '@pepconnect/services/analytics.service';
import { UimcSingleSelectItems } from '@pepconnect/shared/presentation/forms/form-models/single-select-item.model';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';

type AddFileToPlanForm = {
  selectedPlan: FormControl<string>,
  name: FormControl<string>,
  assignToAllMembers: FormControl<boolean>
}

@Component({
  selector: 'app-team-virtual-library-item',
  templateUrl: './team-virtual-library-item.component.html',
  styleUrls: ['./team-virtual-library-item.component.scss']
})
export class TeamVirtualLibraryItemComponent extends BaseTeamMemberComponent implements OnInit {
  @Output() updated = new EventEmitter<void>();

  member: TeamMember;
  teamMember: TeamMember;

  @Input() file: TeamFile;
  @Input() team: Team;
  showAddPlan = false;
  plans: LearningPlan[];
  addFileToPlanForm: FormGroup<AddFileToPlanForm>;
  authState: AuthState;
  options: UimcSingleSelectItems;

  constructor(protected store: Store<AppState>,
    private router: Router,
    private translate: TranslateService,
    private linksService: LinksService,
    private teamLearningPlanService: TeamLearningPlanService,
    private fb: FormBuilder,
    private analyticsService: AnalyticsService) { super(store); }

  ngOnInit() {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (response) => {
        this.authState = response;
      }
    })
  }

  goToFile() {
    if (!this.file.isReady) return;
    const path = this.file.links.find(link => link.rel === LinkRel.page)?.href;
    this.router.navigate([path]);
  }

  fileAction(file: TeamFile, action: Link, $event: Event, el: HTMLElement) {
    // catch marcom click handler to collapse menu after selection
    (el as any)._onClose();

    $event.stopPropagation();
    $event.preventDefault();

    switch (action.rel) {
      case LinkRel.editFile:
        this.router.navigate([action.href]);
        break;
      case LinkRel.deleteFile:
        const confirmMessage = this.translate.instant('TEXT_DELETE_TEAMFILE');
        if (confirm(confirmMessage)) {
          this.linksService.postAction(action, true).pipe(take(1)).subscribe(() => {
            this.updated.emit();
          });
        }
        break;
      case LinkRel.addToLearningPlan:
        this.teamLearningPlanService.fetchFilePlans(this.team.teamID, file.friendlyURL).subscribe({
          next: (response) => {
            this.showAddPlan = true;
            this.plans = response;
            this.options = this.plans.map(plan => ({
              value: plan,
              name: plan.name,
              isSelected: false
            }));

            this.options.unshift({
              name: this.translate.instant('LABEL_CREATENEWPLAN'),
              value: null,
              isSelected: true
            })

            this.setUpForm();
          }
        })
        break;
      default:
        this.linksService.postAction(action, true).pipe(take(1)).subscribe(() => {
          this.updated.emit();
        });
        break;
    }
  }

  setUpForm() {
    this.addFileToPlanForm = this.fb.group({
      selectedPlan: this.fb.control(this.options[0].value),
      name: this.fb.control(''),
      assignToAllMembers: this.fb.control(false)
    }, {
      validators: [(control: AbstractControl): ValidationErrors | null => {
        const plan = control.get('selectedPlan');
        const name = control.get('name');

        if (plan.value) {
          return null;
        }

        return Validators.required(name);
      }]
    });
  }

  get f() {
    return this.addFileToPlanForm?.controls;
  }

  createNewPlan() {
    this.teamLearningPlanService.useFilewithPlan(this.team.teamID, this.file.friendlyURL, this.f.name.value, this.f.assignToAllMembers.value).pipe(take(1)).subscribe({
      next: (planId: string) => {

        this.router.navigate([this.authState?.user?.locale?.locale, 'groups', this.team.friendlyURL, 'plans', planId, 'objects']);
      }
    })
    this.analyticsService.trackGroup({
      action: 'group.learningplancreate',
      data: {
        group: {
          name: this.team?.name,
          groupType: this.translate.instant(this.team?.teamType?.name)
        }
      }
    });
  }

  addToPlan() {
    this.teamLearningPlanService.useFilewithPlan(this.team.teamID, this.file.friendlyURL, this.addFileToPlanForm.controls.selectedPlan.value, null).pipe(take(1)).subscribe({
      next: (planId) => {
        this.router.navigate([this.authState?.user?.locale?.locale, 'groups', this.team.friendlyURL, 'plans', planId, 'objects']);
      }
    })
  }

  doAddPlan() {
    if (!this.addFileToPlanForm || this.addFileToPlanForm.invalid) {
      return;
    }

    if (this.isCreatingPlan()) {
      this.createNewPlan();
    } else {
      this.addToPlan();
    }
  }

  onSelectAllMembers($event) {
    this.f.assignToAllMembers.setValue($event);
  }

  isCreatingPlan(): boolean {
    return !this.f.selectedPlan.value;
  }

}

