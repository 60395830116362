import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { UserAvatarSize } from '@pepconnect/shared/presentation/profile/user-avatar/user-avatar.component';
import { AppState } from '@pepconnect/state/index';
import { takeUntil } from 'rxjs';
import { BaseTeamsComponent } from '../../base/base-teams.component';

/**
 * Member Avatar component.
 * Capable of displaying members in a list (teams page) or grid format (team page).
 *
 * When displayed in a grid and more than the specified limit of members are present, the component will display a "more" link
 * and subtract one member to make room.
 *
 * Defaults to a grid display and a limit of 9 members.
 */
@Component({
  selector: 'app-member-avatar-list',
  templateUrl: './member-avatar-list.component.html',
  styleUrls: ['./member-avatar-list.component.scss']
})
export class MemberAvatarListComponent extends BaseTeamsComponent implements OnInit {

  teamMembers: TeamMember[];
  hasMore: boolean;
  @Input() size: UserAvatarSize;
  @Input() display: 'grid' | 'list' = 'grid';
  @Input() limit: number | string = 9;
  membersPage: string;
  moreCount: number;

  constructor(private teamStore: TeamStore, private teamService: TeamsService, protected store: Store<AppState>) { super(store); }

  ngOnInit(): void {
    if (typeof this.limit === 'string') {
      this.limit = parseInt(this.limit);
    }
    this.teamStore.$teamMember.pipe(takeUntil(this.ngUnsubscribe)).subscribe(teamMember => {
      this.teamMember = teamMember;
      this.setMembersPage();
    });
    this.teamStore.$activeTeamMembers.pipe(takeUntil(this.ngUnsubscribe)).subscribe(members => this.setMembers(members));

  }

  setMembers(members: TeamMember[]) {

    let parsedLimit: number;
    if (typeof this.limit === 'string') {
      parsedLimit = parseInt(this.limit);
    } else {
      parsedLimit = this.limit;
    }
    if (members.length > parsedLimit) {
      this.hasMore = true;
      this.moreCount = members.length - (parsedLimit - 1); // we take away one to account for taking away a spot for the "more" link
    }
    const limit = this.hasMore && this.display === 'grid' ? parsedLimit - 1 : parsedLimit;
    this.teamMembers = this.teamService.teamHelpers.ownerSort(members).slice(0, limit);
  }

  setMembersPage() {
    const target = this.teamMember.team.links.find(l => l.rel === LinkRel.page);
    if (this.teamMember.team.teamType.showTeamCustomerEventsRoster) {
      this.membersPage = target.href;
    } else {
      this.membersPage = `${target.href}/members`;
    }

  }

  trackByMember(_, item: TeamMember) {
    return item.id;
  }

}
