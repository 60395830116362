<div *ngIf="member" id="group-join-invite-container" class="group-invite-card">
    <a (click)="linkUrl()">
        <div *ngIf="!isGroupDetailPage">
            <app-team-header size="small" [showBackButton]="false" [teamMember]="member" [team]="member.team" [showStatus]="true"></app-team-header>
        </div>
        <div [class.card-content]="!isGroupDetailPage">
            <div *ngIf="isGroupDetailPage">{{member?.statusText | translate}}</div>

            <div *ngIf="showCardContent()">
                <div *ngIf="isMemberInvited()">
                    <small><strong>{{ 'TEXT_DEFINE_PRIVACY_SETTINGS' | translate }}</strong></small>
                </div>
                <form #mform [formGroup]="groupInviteForm" (ngSubmit)="accept($event, member)">
                    <app-team-privacy-settings *ngIf="isMemberInvited()" [optInActivitiesControl]="f.optInActivities"
                        [optInPepActivitiesControl]="f.optInPepActivities">
                    </app-team-privacy-settings>
                    <div class="action-btns">
                        <button *ngFor="let action of member.memberActions" class="button" [class.button--primary]="shouldBePrimary(action)" type="submit"
                            (click)="(action.text === 'GROUP_MEMBER_ACCEPTINVITE') ? submitFormWithoutLinkClick($event) : deny($event, member)">
                            {{action.text | translate}}
                        </button>
                    </div>
                </form>
                <p *ngIf="isEULArequired()">{{'EULA_PRELINK_TEXT'| translate}} <app-eula-link></app-eula-link></p>
            </div>
        </div>
    </a>
</div>
