import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamLearningPlanService } from '@pepconnect/features/teams/services/team-learning-plan.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AppState } from '@pepconnect/state/index';
import { take } from 'rxjs';
import { Locale } from '@pepconnect/models/localization/locale.model';
import { UserLearningPlan } from '@pepconnect/models/plan/user-learning-plan.model';
import { LearningPlanHelpers } from '@pepconnect/shared/plans-shared/services/plan-helpers.service';
import { isPopulated } from '@pepconnect/utils/generic-utils';


@Component({
  selector: 'app-member-my-learning-plans',
  templateUrl: './member-my-learning-plans.component.html'
})
export class MemberMyLearningPlansComponent extends BaseSmartComponent implements OnInit {
  @Input() member: TeamMember;
  userLocale: Locale;
  plans: UserLearningPlan[];
  hasAssignedPlans: boolean;
  loading: boolean = true;

  constructor(
    protected store: Store<AppState>,
    private teamLearningPlansService: TeamLearningPlanService,
    private planHelpers: LearningPlanHelpers) {
    super(store);
  }

  ngOnInit(): void {
    this.$userLocale.pipe(take(1)).subscribe(locale => {
      this.userLocale = locale;
      this.fetchAssignedPlans();
    });
  }

  onPlanClick() {
    this.loading = true;
  }

  fetchAssignedPlans(): void {
    this.teamLearningPlansService.fetchTeamMemberAssignedPlans(this.member.team.teamID, this.userLocale.id).pipe(take(1)).subscribe(plans => {
      this.plans = this.planHelpers.sortPlans(plans);
      this.hasAssignedPlans = isPopulated(this.plans);
      this.loading = false;
    });
  }
}
