import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { AppState } from '@pepconnect/state';
import { selectUserLocaleCode } from '@pepconnect/state/auth/auth.selector';
import { CreditBucket } from '@pepconnect/features/teams/models/team-credit-bucket.model';

@Component({
  selector: 'app-bucket-list',
  templateUrl: './bucket-list.component.html'
})
export class BucketListComponent implements OnInit{
  readonly userLocaleCode$ = this.store.pipe(select(selectUserLocaleCode));
  @Input() title: string;
  @Input() buckets: CreditBucket[];
  userLocale: string;

  constructor(protected store: Store<AppState>, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.userLocaleCode$
      .pipe(take(1))
      .subscribe((locale) => {
        this.userLocale = locale;
      })
  }

  getBucketTitle(b: CreditBucket): string {
    return `${b.credits - b.creditsUsed} ${this.translateService.instant('TEXT_CREDITS')} (${formatDate(b.startDate, 'longDate', this.userLocale)} - ${formatDate(b.expirationDate, 'longDate', this.userLocale) })`;
  }
}
