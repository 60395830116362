<div class="library-container" *ngIf="member?.showVirtualLibrary">
    <div class="card-top">
        <div class="title-sort">
            <h5 class="margin-top-0 font-family-headline">{{ 'TITLE_GROUPFILES' | translate}}</h5>
            <uimc-custom-dropdown #fileSort *ngIf="teamFile?.files?.length > 1" class="sort-dropdown">
                <div slot="content"><strong>{{'LABEL_SORT' | translate}}</strong></div>
                <uimc-menu>
                    <uimc-menu-item (click)="sortFiles('title','asc',fileSort)">
                        {{'FILTER_ATZ' | translate}}</uimc-menu-item>
                    <uimc-menu-item (click)="sortFiles('title','desc',fileSort)">
                        {{'FILTER_ZTA' | translate}}</uimc-menu-item>
                    <uimc-menu-item (click)="sortFiles('modified','desc',fileSort)">
                        {{'FILTER_DATES_HIGH' | translate}}</uimc-menu-item>
                    <uimc-menu-item (click)="sortFiles('modified','asc',fileSort)">
                        {{'FILTER_DATES_LOW' | translate}}</uimc-menu-item>
                </uimc-menu>
            </uimc-custom-dropdown>
        </div>
        <div *ngIf="member?.isAdmin">
            {{(teamFile?.byteSizeLibrary == 0 ? "0 kB" : (teamFile?.byteSizeLibrary | byteFormat:2))}} /
            {{teamFile?.byteSizeLimit | byteFormat:2}}
        </div>
        <div *ngIf="showAlmostLimitMessage()" role="alert">{{'TEXT_TEAMFILE_ALMOSTLIMIT' | translate}}</div>
        <div *ngIf="showTeamFileLimitMessage()" role="alert">{{'TEXT_TEAMFILE_LIMIT' | translate}}</div>
    </div>
    <div class="library-cards" *ngFor="let file of teamFile?.files.slice(0, numberOfCardsToShow)">
        <app-team-virtual-library-item [file]="file" [team]="member?.team" (updated)="fetchTeamFiles()"></app-team-virtual-library-item>
    </div>
    <button class="lib-button" *ngIf="viewAllButton()" (click)="showAll()">
        <span class="icon-arrow-right-large">
            {{ 'LINK_VIEWALL' | translate }}
        </span>
    </button>
    <button class="lib-button" *ngIf="showLessButton()" (click)="showLess()">
        <span class="icon-arrow-right-large">
            {{ 'LINK_SHOWLESS' | translate }}
        </span>
    </button>
    <div *ngIf="member?.isAdmin">
        <div class="icon-plus upload-section" (click)="goToNewFile()">{{ 'LINK_CREATETEAMFILE' | translate}}</div>
    </div>
</div>
