import { Component, Input, OnInit, inject } from '@angular/core';
import { TeamFile } from '@pepconnect/features/teams/models/team-library.model';
import { Team } from '@pepconnect/features/teams/models/team.model';
import { TeamFileService } from '@pepconnect/features/teams/services/team-file.service';
import { VJSSources } from '@pepconnect/models/media/vjs-source.model';
import { BaseComponent } from '@pepconnect/shared/base/base-component/base.component';
import { VideoCompletionMeasurement } from '@pepconnect/shared/base/base-video-player/services/progress/video-progress.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-team-virtual-library-player',
  templateUrl: './team-virtual-library-player.component.html',
  styleUrl: './team-virtual-library-player.component.scss'
})
export class TeamVirtualLibraryPlayerComponent extends BaseComponent implements OnInit {

  @Input() team: Team;
  @Input() teamFile: TeamFile;

  public VideoCompletionMeasurement = VideoCompletionMeasurement;
  private teamFileService = inject(TeamFileService);

  teamFileThumbnail: string;
  teamFileVideoSources: VJSSources;

  ngOnInit(): void {
    this.teamFileVideoSources = this.teamFile.sources;
    this.teamFileThumbnail = this.teamFile.thumbnailURL;
  }

  watchedVideo(): void {
    this.logLaunch();
  }

  private logLaunch(): void {
    this.teamFileService.recordTeamFileLaunch(this.team.teamID, this.teamFile.friendlyURL).pipe(take(1)).subscribe();  // fire and forget
  }

}
