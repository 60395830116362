import { Component, Input } from '@angular/core';
import { TeamReportsService } from '@pepconnect/features/teams/services/team-reports.service';
import { UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { take } from 'rxjs';

@Component({
  selector: 'app-team-points-report',
  templateUrl: './team-points-report.component.html',
  styles: [
  ]
})
export class TeamPointsReportComponent {
  @Input() teamId: string;
  processing: boolean = false;
  errorMessage: string;

  constructor(private teamReportsService: TeamReportsService) { }

  printCreditsPdf(): void {
    this.printCredits(true);
  }

  printCreditsExcel(): void {
    this.printCredits(false);
  }

  private printCredits(isPdf: boolean): void {
    this.processing = true;
    this.errorMessage = null;
    this.teamReportsService
      .printCredits(this.teamId, isPdf)
      .pipe(take(1))
      .subscribe({
        next: (printId) => {
          this.processing = false;
        },
        error: (err: UnserializedResponseMessage) => {
          this.processing = false;
          this.errorMessage = err?.Message;
        }
      });
  }
}
