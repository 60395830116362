<app-alert indicator="success" *ngIf="successMessage"> {{ successMessage | translate }} </app-alert>
<app-alert indicator="error" *ngIf="errorMessage">{{ errorMessage | translate }} </app-alert>
<app-form-full-width *ngIf="teamMember && member" [formGroup]="assignForm" (onSubmit)="save()">
    <app-form-group>
        <app-assignable-plan-selector [teamId]="teamMember.team.teamID" [memberId]="member.id" [control]="f.teamLearningPlanID" [$refresh]="$refresh"></app-assignable-plan-selector>
        <app-validation-message [control]="f.teamLearningPlanID" error="required" message="LABEL_PLANREQUIRED" [showOnSubmitted]="true"></app-validation-message>
    </app-form-group>
    <app-form-group>
        <app-date-picker [label]="'LEARNING_STATUS_DUE' | translate" [control]="f.dueDate"></app-date-picker>
        <app-validation-message [control]="f.dueDate" error="greaterThanToday" [showOnSubmitted]="true"></app-validation-message>
    </app-form-group>
    <app-form-buttons>
        <button class="button button--primary" [disabled]="submitting">{{ 'BUTTON_SAVE' | translate }}</button>
        <button type="button" class="button button--secondary" (click)="onCancel()">{{ 'BUTTON_CANCEL' | translate }}</button>
    </app-form-buttons>
</app-form-full-width>
