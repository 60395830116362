<uimc-custom-dropdown class="menu-dropdown" right-aligned *ngIf="member && teamMember && teamMember.isAdmin">
    <div slot="content">
        <span class="icon-dots-ellipsis-vertical color-black-60" role="button"></span>
    </div>
    <uimc-menu>
        <uimc-menu-item *ngFor="let action of actions" (click)="dispatch(action)">{{ action.text | translate }}</uimc-menu-item>
    </uimc-menu>
</uimc-custom-dropdown>
<uimc-card horizontal *ngIf="member && teamMember" [url]="teamMember.isAdmin ? (member | link: 'page') : ''" [id]="'member-card-' + member.id">
    <app-member-details [member]="member"></app-member-details>
</uimc-card>
