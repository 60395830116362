import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamMemberActionsService } from '@pepconnect/features/teams/services/team-member-actions.service';
import { BaseTeamMemberComponent } from '@pepconnect/features/teams/teams-shared/base/base-team-member.component';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { Link } from '@pepconnect/models/general/link.model';
import { AppState } from '@pepconnect/state/index';
import { takeUntil } from 'rxjs';

/** Base Team Card component with common helper functions and properties for conformity.
 *  Note: For Owner Action or Member Action helpers, see TeamMemberActionsService
 */
@Component({ template: '' })
export class BaseMemberCardComponent extends BaseTeamMemberComponent implements OnInit {
  @Input() member: TeamMember;
  /** Optional - Can be set via TeamStore */
  @Input() teamMember: TeamMember;
  @Output() onActionDispatched = new EventEmitter();
  actions: Array<Link>;
  constructor(
    protected store: Store<AppState>,
    protected teamStore: TeamStore,
    protected teamMemberActionService: TeamMemberActionsService
  ) { super(store) }

  ngOnInit(): void {
    if (!this.teamMember) {
      this.setTeamMemberFromStore();
    }
    else {
      this.setActions();
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if (propName == 'member' && this.member && this.teamMember) {
        this.setActions();
      }
    }
  }

  setActions() {
    if(this.member.selectedPlan) {
      this.actions = this.teamMemberActionService.createPlanMemberActionList(this.member, this.teamMember);
    } else {
      this.actions = this.teamMemberActionService.createActionList(this.member, this.teamMember);
    }
  }

  dispatch(action: Link) {
    this.teamMemberActionService.dispatch(action, this.member, this.teamMember, true).subscribe((res) => {
      if (res) {
        this.teamStore.loadTeamMembers(this.teamMember.team.teamID);
      }
      this.onActionDispatched.emit();
    });
  }

  setTeamMemberFromStore() {
    this.teamStore.$teamMember.pipe(takeUntil(this.ngUnsubscribe)).subscribe(tm => {
      this.teamMember = tm;
      this.setActions();
    });
  }
}
