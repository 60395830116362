
<app-collection-item *ngIf="loading">
    <app-spinner></app-spinner>
</app-collection-item>
<div *ngIf="!loading">
    <div *ngIf="hasAssignedPlans">
        <div *ngFor="let plan of plans">
            <app-collection-item class="clickable-collection-item">
                <app-member-plan-assignment-mini [userLearningPlan]="plan" (click)="onPlanClick()"></app-member-plan-assignment-mini>
            </app-collection-item>
        </div>
    </div>
    <app-collection-item *ngIf="!hasAssignedPlans">
        <p>{{'TEXT_GROUP_NOPLANS' | translate}}</p>
    </app-collection-item>
</div>
