import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { of, takeUntil } from 'rxjs';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { AppState } from '@pepconnect/state/index';
import { BaseTeamMemberComponent } from '../../base/base-team-member.component';
import { TeamSubscription } from '@pepconnect/features/teams/models/team-subscription';
import { Router } from '@angular/router';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-team-subscription-list',
  templateUrl: './team-subscription-list.component.html',
  styleUrls: ['./team-subscription-list.component.scss']
})
export class TeamSubscriptionListComponent extends BaseTeamMemberComponent implements OnInit {

  @Input() member: TeamMember;
  @Input() isSubscriptionBrowsePage = false;
  @Input() isExpiredSubSection = false;
  @Input() isUpcomingSubSection = false;

  teamMember: TeamMember;
  teamSubscriptions: TeamSubscription[];
  teamExpiredSubscriptions: TeamSubscription[];
  teamUpcomingSubscriptions: TeamSubscription[];

  header = ''

  constructor(protected store: Store<AppState>, private teamsService: TeamsService, private router: Router, private translate: TranslateService) { super(store); }

  ngOnInit(): void {
    this.teamsService.fetchTeamSubscriptions(this.member.team.teamID).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (response) => {
        if (response) {
          // active subs
          this.teamSubscriptions = response.filter(subs => !subs.isExpired && subs.isStarted);

          // upcoming (not yet started) subs
          this.teamUpcomingSubscriptions = response.filter(subs => !subs.isExpired && !subs.isStarted)

          // expired subs
          this.teamExpiredSubscriptions = response.filter(subs => subs.isExpired);
        }
      },
      error: () => {
        return of(null);
      }
    });

    this.sectionHeader();
  }

  sectionHeader() {
    if (this.isSubscriptionBrowsePage && !this.isExpiredSubSection)
      this.header = this.translate.instant('TITLE_ACTIVESUBSCRIPTIONS');

    if (!this.isSubscriptionBrowsePage && !this.isExpiredSubSection && !this.isUpcomingSubSection)
      this.header = this.translate.instant('TITLE_SUBSCRIPTIONS');

    if (!this.isSubscriptionBrowsePage && this.isExpiredSubSection)
      this.header = this.translate.instant('TITLE_EXPIREDSUBSCRIPTIONS');

    if (!this.isSubscriptionBrowsePage && !this.isExpiredSubSection && this.isUpcomingSubSection) {
      this.header = this.translate.instant('TITLE_UPCOMING_SUBSCRIPTIONS');
    }
  }

  redirectToSubPage() {
    let path = this.member?.team?.links?.find(link => link.rel === LinkRel.page)?.href;
    this.router.navigate([path, 'subscriptions']);
  }

}

