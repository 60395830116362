<app-spinner *ngIf="loading"></app-spinner>

<small class="color-red-dark" *ngIf="hasError">{{errorMessage | translate}}</small>

<div id="create-group-learning-plan-form" *ngIf="!loading">
    <form [formGroup]="createLearningPlanForm" (ngSubmit)="createGroupLearningPlan()">
        <!-- tier dropdown -->
        <app-form-group>
            <label>{{'SEARCH_CLINICAL_AREAS' | translate}}</label>
            <uimc-filtered-select-v2 formControlName="selectedTier"
                [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate"
                [label]="'SEARCH_CLINICAL_AREAS' | translate"
                [filterThreshold]="1">
                <option *ngFor="let tier of tiers" [value]="tier.value">{{tier.name | translate}}</option>
            </uimc-filtered-select-v2>
        </app-form-group>
        <!-- template dropdown -->
        <app-form-group>
            <label>{{'LABEL_TEMPLATE' | translate}}</label>
            <uimc-filtered-select-v2 formControlName="selectedTemplate"
                [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate"
                [label]="'TITLE_GROUPLEARNINGPLANS' | translate"
                [filterThreshold]="1"
                (selectedItemChange)="onSelectedTemplate($event)">
                <option *ngFor="let option of options" [value]="option.value">{{option.name | translate}}</option>
            </uimc-filtered-select-v2>
        </app-form-group>
        <!-- template name -->
        <app-form-group>
            <app-textfield-group [skip-invalid]="false">
                <label class="textfield__label" for="name">{{ 'LABEL_NAME' | translate }}</label>
                <input type="text" class="textfield__input" name="name" formControlName="name">
                <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true" [control]="f.name"></app-form-hint>
                <app-validation-message [control]="f.name" [showOnSubmitted]="true" error="required"
                    message="REQUIRED_FIELD"></app-validation-message>
            </app-textfield-group>
        </app-form-group>
        <!-- assign all members -->
        <uimc-checkbox class="margin-top-single margin-top-bottom-single" [value]="true" formControlName="assignToAllMembers">
            <small><strong>{{'LABEL_AUTOASSIGN' | translate}}</strong></small>
        </uimc-checkbox>
        <!-- due date for plan completion -->
        <div class="display-inline-block" *ngIf="f.assignToAllMembers.value">
            <label>{{'LABEL_DUE_IN' | translate}}:
                <div>
                    <input type="number" formControlName="dueIn">
                    {{'LABEL_DAYS_OPTIONAL' | translate}}
                </div>
                <br>
                <small>{{'TEXT_DUE_DAYS_INFO' | translate}}</small>
            </label>
        </div>
        <button class="button button--primary margin-top-single margin-top-bottom-single display-flex">
            {{ 'LABEL_CREATE' | translate}}
        </button>
    </form>
</div>