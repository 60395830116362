<div class="display-flex row margin-top-0">
    <div class="col-12">
        <h6>{{title | translate}}</h6>
    </div>
</div>
<div class="display-flex row">
    <div class="col-12" *ngFor="let b of buckets" [id]="'bucket-' + b.id">
        <span [innerText]="getBucketTitle(b)"></span>
    </div>
</div>
