import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberAvatarCardComponent } from './components/member-avatar-card/member-avatar-card.component';
import { SharedModule } from '@pepconnect/shared/module';
import { MarcomModule } from '@pepconnect-marcom/marcom.module';
import { TeamHeaderComponent } from './components/team-header/team-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MemberCardComponent } from './components/member-card/member-card.component';
import { TeamInviteMemberComponent } from './components/team-invite-member/team-invite-member.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MemberDetailsComponent } from './components/member-details/member-details.component';
import { AssignablePlanSelectorComponent } from './components/selectors/assignable-plan-selector/assignable-plan-selector.component';
import { MemberAssignPlanComponent } from './components/member-assign-plan/member-assign-plan.component';
import { MemberTranscriptComponent } from './components/member-transcript/member-transcript.component';
import { MemberTranscriptCardComponent } from './components/member-transcript/member-transcript-card/member-transcript-card.component';
import { MemberPlanAssignmentsComponent } from './components/member-plan-assignments/member-plan-assignments.component';
import { TeamCountryWarningComponent } from './components/team-country-warning/team-country-warning.component';
import { TeamVirtualLibraryItemComponent } from './components/team-virtual-library/team-virtual-library-item/team-virtual-library-item.component';
import { TeamVirtualLibraryListComponent } from './components/team-virtual-library/team-virtual-library-list.component';
import { PlansSharedModule } from '@pepconnect/shared/plans-shared/plans-shared.module';
import { TeamSubscriptionListComponent } from './components/team-subscriptions/team-subscription-list.component';
import { TeamSubscriptionItemComponent } from './components/team-subscriptions/team-subscription-item/team-subscription-item.component';
import { PurchasePointPickerComponent } from './components/team-atm/purchase-point-picker.component';
import { TeamTransferPointsComponent } from './components/team-transfer-points/team-transfer-points.component';
import { BrowseSubscriptionComponent } from './components/browse-subscription/browse-subscription.component';
import { SubscriptionActionComponent } from './components/subscription-action/subscription-action.component';
import { MemberAvatarListComponent } from './components/member-avatar-list/member-avatar-list.component';
import { EducationPlanningToolLinkComponent } from './components/education-planning-tool-link/education-planning-tool-link.component';
import { TeamSettingsComponent } from './components/team-settings/team-settings.component';
import { TeamRemoveMeComponent } from './components/team-remove-me/team-remove-me.component';
import { MemberMyLearningPlansComponent } from './components/member-my-learning-plans/member-my-learning-plans.component';
import { BucketListComponent } from './components/team-points-history/bucket-list/bucket-list.component';
import { TeamDeactivateModalComponent } from './components/team-deactivate-modal/team-deactivate-modal.component';
import { MemberRequestComponent } from './components/member-request/member-request.component';
import { MemberJoinInviteComponent } from './components/member-join-invite/member-join-invite.component';
import { TeamPrivacyToggleComponent } from './components/selectors/team-privacy-toggle/team-privacy-toggle.component';
import { TeamPointsHistoryComponent } from './components/team-points-history/team-points-history.component';
import { TeamPointsReportComponent } from './components/team-points-report/team-points-report.component';
import { TeamGroupLearningPlanComponent } from './components/team-learning-plan/team-group-learning-plan.component';
import { TeamGroupLearningPlanItemComponent } from './components/team-learning-plan/group-learning-plan-item/team-group-learning-plan-item.component';
import { CreateLearningPlanComponent } from './components/create-learning-plan/create-learning-plan.component';
import { TeamVirtualLibraryPlayerComponent } from './components/team-virtual-library-player/team-virtual-library-player.component';


@NgModule({
  declarations: [
    MemberAvatarCardComponent,
    TeamHeaderComponent,
    MemberCardComponent,
    TeamInviteMemberComponent,
    MemberDetailsComponent,
    AssignablePlanSelectorComponent,
    MemberAssignPlanComponent,
    MemberTranscriptComponent,
    MemberTranscriptCardComponent,
    MemberPlanAssignmentsComponent,
    TeamCountryWarningComponent,
    TeamVirtualLibraryListComponent,
    TeamVirtualLibraryItemComponent,
    TeamSubscriptionListComponent,
    TeamSubscriptionItemComponent,
    PurchasePointPickerComponent,
    TeamTransferPointsComponent,
    BrowseSubscriptionComponent,
    SubscriptionActionComponent,
    MemberAvatarListComponent,
    EducationPlanningToolLinkComponent,
    TeamSettingsComponent,
    TeamRemoveMeComponent,
    MemberMyLearningPlansComponent,
    BucketListComponent,
    TeamDeactivateModalComponent,
    MemberRequestComponent,
    MemberJoinInviteComponent,
    TeamPrivacyToggleComponent,
    TeamPointsHistoryComponent,
    TeamPointsReportComponent,
    TeamPrivacyToggleComponent,
    TeamGroupLearningPlanComponent,
    TeamGroupLearningPlanItemComponent,
    CreateLearningPlanComponent,
    TeamVirtualLibraryPlayerComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MarcomModule,
    TranslateModule.forChild({
      extend: true
    }),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PlansSharedModule
  ],
  exports: [
    MemberAvatarCardComponent,
    TeamHeaderComponent,
    MemberCardComponent,
    TeamInviteMemberComponent,
    MemberDetailsComponent,
    AssignablePlanSelectorComponent,
    MemberAssignPlanComponent,
    MemberTranscriptComponent,
    MemberTranscriptCardComponent,
    MemberPlanAssignmentsComponent,
    TeamCountryWarningComponent,
    TeamVirtualLibraryListComponent,
    TeamVirtualLibraryItemComponent,
    TeamSubscriptionListComponent,
    TeamSubscriptionItemComponent,
    PurchasePointPickerComponent,
    TeamTransferPointsComponent,
    BrowseSubscriptionComponent,
    SubscriptionActionComponent,
    MemberAvatarListComponent,
    TeamSettingsComponent,
    TeamRemoveMeComponent,
    MemberMyLearningPlansComponent,
    EducationPlanningToolLinkComponent,
    MemberRequestComponent,
    MemberJoinInviteComponent,
    TeamPointsHistoryComponent,
    TeamPointsReportComponent,
    TeamGroupLearningPlanComponent,
    TeamGroupLearningPlanItemComponent,
    CreateLearningPlanComponent,
    TeamVirtualLibraryPlayerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TeamsSharedModule { }
