import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-education-planning-tool-link',
  templateUrl: './education-planning-tool-link.component.html',
  styles: [
  ]
})
export class EducationPlanningToolLinkComponent implements OnInit {

  constructor() {
      /* to do */
  }

  ngOnInit(): void {
    /* to do */
  }

  goToEpt(): void {
    /* to do */
  }
}
