import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { TeamFileService } from '@pepconnect/features/teams/services/team-file.service';
import { AppState } from '@pepconnect/state/index';
import { of, takeUntil } from 'rxjs';
import { TeamLibrary } from '../../../models/team-library.model';
import { TeamMember } from '../../../models/team-member.model';
import { TeamsService } from '../../../services/teams.service';
import { BaseTeamMemberComponent } from '../../base/base-team-member.component';

@Component({
  selector: 'app-team-virtual-library-list',
  templateUrl: './team-virtual-library-list.component.html',
  styleUrls: ['./team-virtual-library-list.component.scss']
})
export class TeamVirtualLibraryListComponent extends BaseTeamMemberComponent implements OnInit {

  // teamMember who is logged in
  @Input() member: TeamMember;
  teamMember: TeamMember;
  teamFile: TeamLibrary;
  numberOfCardsToShow: number;

  constructor(protected store: Store<AppState>, private teamsService: TeamsService, private teamFileService: TeamFileService, private router: Router) { super(store); }

  ngOnInit(): void {
    this.fetchTeamFiles();
  }

  fetchTeamFiles() {
    this.teamFileService.fetchTeamFiles(this.member?.team?.teamID).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (response) => {
        if (response) {
          this.numberOfCardsToShow = 5;
          this.teamFile = response;
        }
        const sort = JSON.parse(sessionStorage.getItem(constants.SS_SORT_VIRTUAL_LIB));
        if (sort) {
          this.sortFiles(sort.sortName, sort.order);
        }
      },
      error: (err) => {
        // placeHolder for error message usage when api fails
      }
    })
  }

  goToNewFile() {
    if (this.teamFile.byteSizeLibrary < this.teamFile.byteSizeLimit * 0.999) {
      let path = this.member?.team?.links.find(link => (link.rel === LinkRel.page))?.href;
      this.router.navigate([path, 'files', 'new']);
    }
  }

  showAll() {
    this.numberOfCardsToShow = this.teamFile?.files?.length ?? 0;
  }

  viewAllButton(): boolean {
    return (this.teamFile?.files?.length > this.numberOfCardsToShow);
  }

  showLess() {
    this.numberOfCardsToShow = 5;
  }

  showLessButton(): boolean {
    return (this.teamFile?.files?.length <= this.numberOfCardsToShow) && (this.teamFile?.files.length > 5);
  }

  sortFiles(propName: string, order: string, el?: HTMLElement) {
    // collapse menu after selection
    if (el) {
      (el as any)._onClose();
    }
    const sort = { sortName: propName, order: order };
    sessionStorage.setItem(constants.SS_SORT_VIRTUAL_LIB, JSON.stringify(sort));

    // title sort
    if (propName === 'title') {
      if (order === 'asc') {
        this.teamFile?.files?.sort((a, b) => a.title.localeCompare(b.title));
      } else {
        this.teamFile?.files?.sort((a, b) => b.title.localeCompare(a.title));
      }
    } else if (propName === 'modified') {
      if (order === 'asc') {
        this.teamFile?.files?.sort((a, b) => a.modified.localeCompare(b.modified));
      } else {
        this.teamFile?.files?.sort((a, b) => b.modified.localeCompare(a.modified));
      }
    }
  }

  showAlmostLimitMessage(): boolean {
    return (this.member?.isAdmin && this.teamFile?.byteSizeLibrary > this.teamFile?.byteSizeLimit * 0.8 && this.teamFile?.byteSizeLibrary < this.teamFile?.byteSizeLimit * 0.999 && this.teamFile?.byteSizeLibrary <= this.teamFile?.byteSizeLimit);
  }

  showTeamFileLimitMessage(): boolean {
    return (this.member?.isAdmin && this.teamFile?.byteSizeLibrary >= this.teamFile?.byteSizeLimit * 0.999);
  }

}
