import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { CartService } from '@pepconnect/features/checkout/services/cart.service';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamSubscription } from '@pepconnect/features/teams/models/team-subscription';
import { subscriptionDefaultImage } from '@pepconnect/functions/default-image';
import { Link } from '@pepconnect/models/general/link.model';
import { TeamAvatar } from '@pepconnect/models/team/team-avatar';
import { AnalyticsService } from '@pepconnect/services/analytics.service';
import { LinksService } from '@pepconnect/services/links.service';
import { ModalsService } from '@pepconnect/services/modals.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { AppState } from '@pepconnect/state/index';
import { isPopulated } from '@pepconnect/utils/generic-utils';
import { of, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-team-subscription-item',
  templateUrl: './team-subscription-item.component.html',
  styleUrls: ['./team-subscription-item.component.scss']
})
export class TeamSubscriptionItemComponent extends BaseSmartComponent implements OnInit {

  @Input() teamSubscription: TeamSubscription;
  @Input() teamMember: TeamMember;
  @Input() isSubscriptionBrowsePage = false;
  @Input() isExpiredSubSection = false;
  @Input() isUpcomingSubSection = false;

  teamSubAvatar: TeamAvatar;
  imagePath: string;
  authState: AuthState;

  constructor(protected store: Store<AppState>,
    private router: Router,
    private linksService: LinksService,
    private analyticsSvc: AnalyticsService,
    private translate: TranslateService,
    private cartService: CartService, private modalsService: ModalsService) { super(store); }

  ngOnInit(): void {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (response) => {
        this.authState = response;
      }
    })

    // Set default image if there is no image for the subscription
    this.imagePath = this.teamSubscription?.subscription?.imagePath ? this.teamSubscription?.subscription?.imagePath :
      subscriptionDefaultImage(this.teamSubscription?.subscription?.type?.name);

    this.teamSubAvatar = {
      teamName: this.teamSubscription.subscription.name,
      avatar: this.imagePath
    };
  }

  linkUrl() {
    const redirectPath = this.teamSubscription.subscription.links.find(link => link.rel === LinkRel.page)?.href;
    this.router.navigate([redirectPath]);
  }

  dispatch(action: Link, $event: Event) {
    $event.stopPropagation();
    switch (action.rel) {
      case LinkRel.enableSubscriptionAccess:
        this.router.navigate([action.href]);
        break;
      case LinkRel.ownerCreatePlanFromSubscription:
        if (isPopulated(this.teamSubscription?.subscription?.filterTags)) {
          const path = this.teamSubscription.subscription.links.find(link => link.rel === LinkRel.page)?.href;
          this.router.navigate([path]);
        } else {
          this.addPlanFromSubscription(action);
        }
        break;
      case LinkRel.ownerPurchase:
      case LinkRel.ownerRenew:
        this.purchaseSub();
        break;
    }

  }

  addPlanFromSubscription(action: Link) {
    this.linksService.postAction<string>(action, true).pipe(take(1)).subscribe({
      next: (planId: string) => {
        this.router.navigate([this.authState?.user?.locale?.locale, 'groups', this.teamMember?.team?.friendlyURL, 'plans', planId, 'objects']);
      },
      error: () => {
        return of(null);
      }
    })
    this.analyticsSvc.trackGroup({
      action: 'group.learningplancreate',
      data: {
        group: {
          name: this.teamMember?.team?.name,
          groupType: this.translate.instant(this.teamMember?.team?.teamType?.name)
        }
      }
    });
  }

  purchaseSub() {
    const orderItem = {
      subscriptionId: this.teamSubscription.subscription.subscriptionID,
      itemType: 14,
      teamMemberId: this.teamMember.id,
      quantity: 1
    };

    // initialize checkout with current route
    this.cartService.initialize(orderItem, this.router.url);
    this.analyticsSvc.trackPurchaseData({
      action: 'cart.checkout',
      data: {
        products: {
          id: this.teamSubscription.subscription.subscriptionID,
          name: `Subscription: ${this.teamSubscription.subscription.friendlyURL}`
        }
      }
    });
    this.cartService.checkout();
  }

  shouldAvatarBeCircle() {
    return this.teamSubscription?.subscription?.type?.hasSquareAvatar && this.teamSubscription?.subscription?.imagePath !== '';
  }

  showActiveSubSection(): boolean {
    return !this.isExpiredSubSection && !this.isUpcomingSubSection;
  }

}
