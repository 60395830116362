<p *ngIf="!transferBuckets?.length || !destinationOptions?.length">{{'TEXT_CREDIT_TRANSFER_NO_CREDITS' | translate }}
</p>
<app-form-full-width *ngIf="transferBuckets?.length && destinationOptions?.length && transferCreditsForm"
    [formGroup]="transferCreditsForm" (onSubmit)="onSubmit()">
    <app-form-group>
        <uimc-filtered-select-v2 formControlName="sourceBucketId"
            id="transfer-source-dropdown"
            [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate"
            [label]="'LABEL_CREDIT_SOURCE_BUCKET' | translate"
            [filterThreshold]="4"
            (selectedItemChange)="onSourceBucketChanged()">
            <option *ngFor="let option of transferOptions" [value]="option.value">{{option.name}}</option>
        </uimc-filtered-select-v2>
        <small class="selectbox__hint" *ngIf="!f.sourceBucketId.touched || f.sourceBucketId.valid">
            {{ 'REQUIRED_FIELD' | translate }}
        </small>
        <small class="color-red-dark selectbox__hint" *ngIf="f.sourceBucketId.touched && f.sourceBucketId.invalid">
            {{ 'REQUIRED_FIELD' | translate }}
        </small>

    </app-form-group>
    <app-form-group>
        <app-textfield-group>
            <label class="textfield__label" for="transfer-credits-amount">
                {{ 'TEXT_NUMCREDITSTRANSFER' | translate }}</label>
            <input class="textfield__input" id="transfer-credits-amount" name="transfer-credits-amount"
                formControlName="creditsAmount" type="number">
            <small class="textfield__hint" *ngIf="!f.creditsAmount.touched || f.creditsAmount.valid">
                {{ 'REQUIRED_FIELD' | translate }}
            </small>
            <small class="color-red-dark textfield__hint"
                *ngIf="f.creditsAmount.touched && f.creditsAmount.invalid && f.creditsAmount.hasError('required')">
                {{ 'REQUIRED_FIELD' | translate }}
            </small>
            <small class="color-red-dark textfield__hint"
                *ngIf="f.creditsAmount.touched && f.creditsAmount.invalid && f.creditsAmount.hasError('min')">
                {{ 'TRANSFER_BUCKET_NOT_ENOUGH_CREDITS' | translate }}
            </small>
            <small class="color-red-dark textfield__hint"
                *ngIf="f.creditsAmount.touched && f.creditsAmount.invalid && f.creditsAmount.hasError('max')">
                {{ 'TRANSFER_BUCKET_NOT_ENOUGH_CREDITS' | translate }}
            </small>
        </app-textfield-group>
    </app-form-group>
    <app-form-group>
        <uimc-filtered-select-v2 id="destinationTeamDropdown" formControlName="destinationTeamId"             
            [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate"
            [label]="'LABEL_GROUP' | translate" 
            [filterThreshold]="2"
            (selectedItemChange)="onSelectedItem($event)">
            <option *ngFor="let destination of destinationOptions" [value]="destination.value">{{destination.name}}</option>
        </uimc-filtered-select-v2>
        <small class="textfield__hint" *ngIf="!f.destinationTeamId.touched || f.destinationTeamId.valid">
            {{ 'REQUIRED_FIELD' | translate }}
        </small>
        <small class="color-red-dark textfield__hint"
            *ngIf="f.destinationTeamId.touched && f.destinationTeamId.invalid">
            {{ 'REQUIRED_FIELD' | translate }}
        </small>
    </app-form-group>
    <app-form-buttons class="margin-top-1">
        <button id="transfer-points-continue" class="button button--primary" type="submit" [disabled]="processing">
            {{ 'BUTTON_TRANSFER'| translate}}</button>
        <app-spinner *ngIf="processing"></app-spinner>
    </app-form-buttons>
</app-form-full-width>