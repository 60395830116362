import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { TranscriptItem } from '@pepconnect/models/transcript/transcript-item.model';
import { AppState } from '@pepconnect/state/index';
import { BaseTeamMemberComponent } from '@pepconnect/teams/teams-shared/base/base-team-member.component';
import { isPopulated } from '@pepconnect/utils/generic-utils';
import { forkJoin, take } from 'rxjs';

@Component({
  selector: 'app-member-transcript',
  templateUrl: './member-transcript.component.html'
})
export class MemberTranscriptComponent extends BaseTeamMemberComponent implements OnInit {
  teamMember: TeamMember;
  member: TeamMember;

  transcriptItemsCache: TranscriptItem[];
  shownTranscriptItems: TranscriptItem[];
  constructor(protected store: Store<AppState>, private teamStore: TeamStore, private teamService: TeamsService) { super(store) }


  ngOnInit(): void {
    forkJoin([this.teamStore.$teamMember.pipe(take(1)), this.teamStore.$selectedMember.pipe(take(1))]).subscribe(([teamMember, selectedMember]) =>  {
      this.teamMember = teamMember;
      this.member = selectedMember;
      this.fetchTranscript();
    });
  }

  fetchTranscript() {
    this.teamService.fetchTeamMemberTranscript(this.teamMember.team.teamID, this.member.id)
    .pipe(take(1))
    .subscribe((transcript => this.setTranscript(transcript)));
  }

  get nextPageEnabled() {
    return isPopulated(this.transcriptItemsCache)
  }

  setTranscript(transcript: TranscriptItem[]) {
    this.transcriptItemsCache = transcript;
    this.shownTranscriptItems = this.transcriptItemsCache.splice(0, constants.DEFAULT_MEMBER_TRANSCRIPT_SIZE); // note, splice mutates the array.  We take until there is nothing left of the cache
  }

  loadMore() {
    const itemsToAdd = this.transcriptItemsCache.splice(0, constants.DEFAULT_MEMBER_TRANSCRIPT_SIZE);
    this.shownTranscriptItems = this.shownTranscriptItems.concat(itemsToAdd);
  }

}
