import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TeamsModalsService } from '@pepconnect/features/teams/services/teams-modals.service';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { AppState } from '@pepconnect/state/index';
import { take } from 'rxjs';
import { BaseTeamsComponent } from '../../base/base-teams.component';

@Component({
  selector: 'app-team-settings',
  templateUrl: './team-settings.component.html'
})
export class TeamSettingsComponent extends BaseTeamsComponent implements OnInit {
  groupInviteForm: FormGroup;
  hasError = false;

  @Output() deactivatedTeam = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private teamService: TeamsService,
    private teamStore: TeamStore,
    protected store: Store<AppState>,
    private teamModalService: TeamsModalsService
    ) { super(store); }

  ngOnInit(): void {
    this.teamStore.$teamMember.subscribe((teamMember) => {
      if (teamMember) {
        this.teamMember = teamMember;
        this.setUpForm();
      }
    });
  }

  setUpForm() {
    this.groupInviteForm = this.fb.group({
      optInActivities: this.fb.control(this.teamMember.transcriptVisible, Validators.required),
      optInPepActivities: this.fb.control(this.teamMember.showUserActivityinFeed, Validators.required)
    });
    this.listenForChanges();
  }

  listenForChanges() {
    this.groupInviteForm.valueChanges.subscribe({
      next: (value) => {
        this.updateSettings();
      }
    });
  }

  updateSettings() {
    this.teamService.updateTranscriptVisibility(this.teamMember.team.teamID, this.groupInviteForm.get('optInActivities').value).pipe(take(1))
    .subscribe({
      next: (_ => { }),
      error: (err) => { this.hasError = true }
    })

  this.teamService.updateUserActivityFeedVisibility(this.teamMember.team.teamID, this.groupInviteForm.get('optInPepActivities').value).pipe(take(1))
    .subscribe({
      next: (_ => { }),
      error: (err) => { this.hasError = true }
    })
  }

  confirmDeactivate() {
    this.teamModalService.definitions.openDeactivateTeam(this.teamMember).subscribe(res => {
      if(res) {
        this.deactivatedTeam.emit();
      }
    });
  }

  get f() {
    return this.groupInviteForm?.controls;
  }

}

