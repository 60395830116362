import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { TeamSubscription } from '@pepconnect/features/teams/models/team-subscription';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { subscriptionDefaultImage } from '@pepconnect/functions/default-image';
import { TeamAvatar } from '@pepconnect/models/team/team-avatar';
import { AppState } from '@pepconnect/state/index';
import { of, takeUntil } from 'rxjs';
import { BaseTeamsComponent } from '../../base/base-teams.component';

@Component({
  selector: 'app-browse-subscription',
  templateUrl: './browse-subscription.component.html',
  styleUrls: ['./browse-subscription.component.scss']
})
export class BrowseSubscriptionComponent extends BaseTeamsComponent implements OnInit {

  @Input() teamId: string;
  subscriptions: TeamSubscription[];
  searchInput = '';
  loading: boolean;

  constructor(protected store: Store<AppState>,
    private teamService: TeamsService,
    private teamStore: TeamStore,
    private router: Router) { super(store); }

  ngOnInit(): void {
    this.loading = true;
    this.teamStore.$teamMember.subscribe(tm => {
      this.teamMember = tm;
      this.team = tm.team;
    });

    this.teamService.fetchBrowseTeamSubscriptions(this.teamId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (res) => {
        this.subscriptions = res;
        // set default image that don't come with imageURL
        for (const sub of this.subscriptions) {
          sub.subscription.imagePath = sub.subscription.imagePath || subscriptionDefaultImage(sub.subscription.type.name);
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        return of(null);
      }
    })
  }

  teamsAvatar(teamSubscription: TeamSubscription): TeamAvatar {
    return {
      teamName: teamSubscription.subscription.name,
      avatar: teamSubscription.subscription.imagePath ? teamSubscription.subscription.imagePath : ''
    }
  }

  goToSubscription(teamSubs: TeamSubscription) {
    const redirectPath = teamSubs.subscription.links.find(link => link.rel === LinkRel.page)?.href;
    this.router.navigate([redirectPath]);
  }

}
