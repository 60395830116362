import { Component, Input, OnInit, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants';
import { PageSubFamily } from '@pepconnect/enums/page-family.enum';
import { MatotEventChildStep } from '@pepconnect/features/teams/matot-shared/enums/steps.enum';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { Team } from '@pepconnect/features/teams/models/team.model';
import { BaseTeamsComponent } from '@pepconnect/features/teams/teams-shared/base/base-teams.component';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { NavigationService } from '@pepconnect/services/navigation.service';
import { UserAvatarSize } from '@pepconnect/shared/presentation/profile/user-avatar/user-avatar.component';
import { AppState } from '@pepconnect/state/index';
import { take, takeUntil } from 'rxjs';

/** Team Header Component
 *  This component can grab the team context from either the Team Store or via an input.
 *
 * Component can show without the ctx of a team member (ie checkout)
 */

export type TeamHeaderSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'app-team-header',
  templateUrl: './team-header.component.html'
})
export class TeamHeaderComponent extends BaseTeamsComponent implements OnInit {

  @Input() showEditIcon = false;
  @Input() subtitle: string;
  @Input() showBackButton = true;
  @Input() teamMember: TeamMember;
  @Input() team: Team;
  @Input() size: TeamHeaderSize = 'large';
  @Input() showStatus = false;
  @Input() breadcrumb: string;

  isSmall = false;
  isMedium = false;
  avatarSize: UserAvatarSize = 'medium';

  h1Classes: string[] = ['h2 margin-top-0'];
  subtitleClasses: string[] = ['h5', 'margin-top-0'];

  navigatedFromPlan = false;
  isActive: boolean;
  isInvited: boolean;
  detailLink: string;
  friendlyUrlTree: string[];
  isRequested: boolean;
  isMatotBulkInvite: boolean;
  matotLink: string;

  constructor(
    @Optional() private teamStore: TeamStore,
    protected store: Store<AppState>,
    private navigationService: NavigationService,
    ) { super(store) }

  ngOnInit(): void {
    if (this.size === 'small') {
      this.isSmall = true;
      this.avatarSize = 'small';
      this.subtitleClasses = ['font-size-medium'];
      this.h1Classes = ['font-size-medium', 'margin-top-qtr margin-bottom-qtr'];
    }
    if (this.size === 'medium') {
      this.isMedium = true;
      this.avatarSize = 'medium';
      this.subtitleClasses = ['font-size-small'];
      this.h1Classes = ['font-size-xlarge', 'margin-top-qtr margin-bottom-qtr'];
    }
    // prefer the teamMember passed in the Input if available
    if (this.teamMember) {
      this.team = this.teamMember.team;
      this.setup();
    } else {
      // fallback to teamStore if no Input.   For this to work, parent component must have a TeamStore provider entry.
      this.teamStore?.$teamMember?.subscribe(tm => {
        this.teamMember = tm;
        this.team = tm.team;
        this.setup();
      });
      // this could be the header is being used outside of the team module (ie object picker)
      if(!this.teamStore && this.team) {
        this.setFriendlyUrlTree();
        this.setRouteFlags();
      }
    }
  }

  setup() {
    this.setIsActive()
    this.setIsInvited();
    this.setIsRequested();
    this.setFriendlyUrlTree();
    this.setDetailLink();
    this.setRouteFlags();
  }

  setIsActive() {
    if (!this.showStatus || !this.teamMember) return false;
    this.isActive = this.teamMember?.isMemberActive;
  }

  setIsInvited() {
    if (!this.showStatus || !this.teamMember) return false;
    this.isInvited = this.teamMemberHelpers.isMemberInvited(this.teamMember);
  }

  setIsRequested() {
    if (!this.showStatus || !this.teamMember) return false;
    this.isRequested = this.teamMemberHelpers.isMemberRequested(this.teamMember);
  }

  setDetailLink() {
    if (!this.teamMember) {
      return false;
    }
    this.detailLink = `/${this.teamMember.user.locale.locale}/groups/${this.team.friendlyURL}/details`;
  }

  setFriendlyUrlTree() {
    const previousRoute = this.navigationService.getPreviousUrl();
    const isPlan = this.isPlanRoute(previousRoute);
    if (isPlan) {
      this.navigatedFromPlan = true;
      this.friendlyUrlTree = ['/', this.team.locale.locale, 'plan'];
    } else {
      this.friendlyUrlTree = ['/', this.team.locale.locale, 'groups', this.team.friendlyURL];
    }
  }

  private isPlanRoute(route: string): boolean {
    let parts = route.split('?')[0].split('/');
    const isCustomerTraining = parts.findIndex(p => p === 'grouptraining') === 4;
    if(!isCustomerTraining) {
      const lastPart = parts.pop();
      return lastPart === constants.ROUTE_PLAN_PAGE;
    }
    return false;
  }


  setRouteFlags() {
    this.$currentRoute.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ data }) => {
      this.isMatotBulkInvite = data?.pageSubFamily === PageSubFamily.Matot && data?.subPage === MatotEventChildStep.Bulk;
      if(this.isMatotBulkInvite) this.matotLink = `/${data?.event?.links?.find(l => l.rel === 'customerTrainingInvite')?.href}`;
    });
  }

}
