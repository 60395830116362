import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AssignPlanDto } from '@pepconnect/features/teams/dtos/assign-plan.dto';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamLearningPlanService } from '@pepconnect/features/teams/services/team-learning-plan.service';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { ControlsOf } from '@pepconnect/shared/types/controls-of';
import { greaterThanTodayValidator } from '@pepconnect/shared/validators/date-validators/date-greater-than-today-validator.directive';
import { AppState } from '@pepconnect/state/index';
import { BaseTeamMemberComponent } from '@pepconnect/teams/teams-shared/base/base-team-member.component';
import { combineLatest, Subject, takeUntil } from 'rxjs';

type PlanRequest = ControlsOf<AssignPlanDto>

@Component({
  selector: 'app-member-assign-plan',
  templateUrl: './member-assign-plan.component.html'
})
export class MemberAssignPlanComponent extends BaseTeamMemberComponent implements OnInit {
  assignForm: FormGroup<PlanRequest>
  errorMessage: string;
  successMessage: string;
  submitting = false;
  initial = false
  $refresh = new Subject<boolean>();
  teamMember: TeamMember;
  member: TeamMember;
  @Output() cancel = new EventEmitter();

  constructor(private teamStore: TeamStore, protected store: Store<AppState>, private fb: FormBuilder, private teamLearningPlanService: TeamLearningPlanService) { super(store) }

  ngOnInit(): void {
    combineLatest([this.teamStore.$teamMember, this.teamStore.$selectedMember]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([teamMember, selectedMember]) =>  {
      this.teamMember = teamMember;
      this.member = selectedMember;
      this.setupForm();
      if(!this.initial) {
        this.initial = true;
      } else {
      // tell the plan selector to re-fetch the assignable plans since the member has changed
      this.$refresh.next(true);
      }
    });
  }

  setupForm() {
    this.assignForm = this.fb.nonNullable.group({
      teamMemberID: this.member.id,
      teamLearningPlanID: this.fb.control('', [ Validators.required ]),
      dueDate: this.fb.control('', [ greaterThanTodayValidator() ])
    });
  }

  get f() {
    return this.assignForm.controls;
  }

  save() {
    this.resetMessages();
    if(this.assignForm.invalid) return;
    this.submitting = true;
    this.teamLearningPlanService.assignPlan(this.teamMember.team.teamID, this.assignForm.getRawValue()).subscribe({ next: (res) => this.onSuccess(res), error: (error) => this.onError(error) });
  }

  onSuccess(res) {
    this.successMessage = res.message;
    this.submitting = false;
    this.$refresh.next(true);
    this.teamStore.loadSelectedTeamMember(this.member.id);
  }

  resetMessages() {
    this.successMessage = undefined;
    this.errorMessage = undefined;
  }

  onError(error: UnserializedResponseMessage) {
    this.errorMessage = error.Message;
    this.submitting = false;
  }

  onCancel() {
    this.cancel.emit();
  }

}
