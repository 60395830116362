<uimc-custom-dropdown>
    <div slot="content">
        <app-user-avatar [userAvatar]="memberAvatar" [size]="size" [showOwnerBadge]="member.isAdmin"></app-user-avatar>
    </div>
      <uimc-menu>
        <uimc-menu-item (click)="gotoMemberDetail()">{{ memberName }}</uimc-menu-item>
        <uimc-menu-item (click)="emailMember()"><span class="icon-email"></span> {{ memberEmail }}</uimc-menu-item>
        <uimc-menu-item *ngFor="let action of actions" (click)="dispatch(action)">{{ action.text | translate }}</uimc-menu-item>
      </uimc-menu>
    </uimc-custom-dropdown>
