<app-spinner *ngIf="loading"></app-spinner>

<div *ngIf="!loading" id="subscription-browse-cards" class="sub-cards">
    <div class="title">
        <strong>{{'TITLE_BROWSEDSUBSCRIPTIONS'|translate}}</strong>
    </div>
    <div class="sub-search margin-top-half margin-top-bottom-single">
        <input class="sub-search-input" name="searchInput" type="text" [placeholder]="'BUTTON_SEARCH' | translate"
            [(ngModel)]="searchInput">
    </div>
    <ng-container *ngFor="let teamSubscription of (subscriptions | search: searchInput)">
        <a (click)="goToSubscription(teamSubscription)">
            <div id="subscription-browse-card" class="subscription-browse-card">
                <div class="sub-image">
                    <app-team-avatar [teamAvatar]="teamsAvatar(teamSubscription)" size="medium"
                        [showOwnerBadge]="false"></app-team-avatar>
                </div>
                <div class="sub-content">
                    <div class="sub-header-content">
                        <h4>{{teamSubscription.subscription.name}}</h4>
                        <p [innerHTML]="teamSubscription.subscription.description"></p>

                        <div class="margin-bottom-single" *ngIf="teamSubscription?.subscription?.showDisclaimer">
                            <span class="icon-exclamationmark"></span>{{teamSubscription?.subscription?.disclaimerText}}
                        </div>
                        <div class="margin-bottom-single"
                            *ngIf="teamSubscription?.subscription.requirePEPConnections && !team.hasPEPConnections && !teamSubscription?.hasSubscription && !team.hasFuturePEPConnections">
                            {{'LABEL_PEPCONNECTIONS_REQUIRED_MESSAGE' | translate}}
                        </div>
                        <div class="margin-bottom-single"
                            *ngIf="teamSubscription?.subscription.requirePEPConnections && !team.hasPEPConnections && !teamSubscription?.hasSubscription && team.hasFuturePEPConnections">
                            <span class="icon-exclamationmark"></span>
                            {{'LABEL_PEPCONNECTIONS_PENDING_MESSAGE' | translate}}
                        </div>
                        <div class="margin-bottom-single"
                            *ngIf="teamSubscription?.hasSubscription && !teamSubscription?.isStarted && !teamSubscription?.isExpired">
                            <span class="icon-exclamationmark"></span> {{'TEXT_SUBSCRIPTION_NOT_STARTED' | translate}}
                        </div>
                        <div class="margin-bottom-single"
                            *ngIf="teamSubscription?.isStarted && teamSubscription?.isExpired">
                            <span class="icon-exclamationmark"></span> {{'TEXT_SUBSCRIPTION_EXPIRED' | translate}}
                        </div>
                    </div>
                    <!-- Subscription Actions -->
                    <app-subscription-action [team]="team" [teamSubscription]="teamSubscription"
                        [teamMember]="teamMember"></app-subscription-action>

                    <div class="price-points-info">
                        <div *ngIf="teamSubscription?.paymentMethod?.canUsePoints" class="price-label">
                            <span>{{teamSubscription?.subscription?.credits}} {{'LABEL_POINTS' | translate}}<span
                                    *ngIf="teamSubscription?.subscription?.licenseType?.isPerUserLicense">
                                    {{'LABEL_PER_MEMBER' | translate}}
                                </span>/{{teamSubscription?.subscription?.subscriptionLength?.name | translate}}
                            </span>
                        </div>
                        <div *ngIf="teamSubscription?.paymentMethod?.canUseCurrency" class="price-label">
                            <span>{{teamSubscription?.subscription?.formattedPrice}}
                                <span *ngIf="teamSubscription?.subscription?.licenseType?.isPerUserLicense">
                                    {{'LABEL_PER_MEMBER' | translate}}
                                </span>
                                /{{teamSubscription?.subscription?.subscriptionLength?.name | translate}}
                            </span>
                        </div>
                    </div>
                    <div *ngIf="!teamMember?.isSiemensOnsiteAdmin && teamMember?.user?.isSiemensEmployee">
                        <span class="icon-check"></span> <span>
                            {{"GROUP_MESSAGE_SUB_EMPLOYEE" | translate}}</span>
                    </div>
                    <div *ngIf="teamMember?.isSiemensOnsiteAdmin && teamSubscription?.ownerActions?.length === 0">
                        <span class="icon-exclamationmark"></span> <span>
                            {{"GROUP_MESSAGE_SUB_ONSITE" | translate}}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="!teamMember?.isSiemensOnsiteAdmin && teamMember?.user?.isSiemensEmployee">
                <span class="icon-check"></span> <span>
                    {{"GROUP_MESSAGE_SUB_EMPLOYEE" | translate}}</span>
            </div>
            <div *ngIf="teamMember?.isSiemensOnsiteAdmin && teamSubscription?.ownerActions?.length === 0">
                <span class="icon-exclamationmark"></span>
                <span>{{"GROUP_MESSAGE_SUB_ONSITE" | translate}}</span>
            </div>
        </a>
    </ng-container>
</div>