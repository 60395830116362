<div *ngIf="errorMessage" id="error-panel">
    <app-alert indicator="error"
               [accent-border]="true">
        {{ errorMessage | translate }}
    </app-alert>
</div>
<app-action-buttons>
    <button class="button button--primary" (click)="printCreditsPdf()" [disabled]="processing">{{ 'REPORT_TYPE_PDF' | translate }}</button>
    &nbsp; <!-- For some reason spacing isn't respected in a button group if you're using components -->
    <button class="button button--primary" (click)="printCreditsExcel()" [disabled]="processing">{{ 'REPORT_TYPE_EXCEL' | translate }}</button>
    <app-spinner *ngIf="processing"></app-spinner>
</app-action-buttons>
