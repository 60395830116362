import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PlanDetailsDto } from '@pepconnect/dtos/plan/plan-details.dto';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamLearningPlanService } from '@pepconnect/features/teams/services/team-learning-plan.service';
import { PlanTemplate } from '@pepconnect/models/plan/plan-template';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AppState } from '@pepconnect/state/index';
import { of, takeUntil } from 'rxjs';

type createLearningPlanForm = {
  selectedTemplate: FormControl<PlanTemplate>,
  name: FormControl<string>,
  assignToAllMembers: FormControl<boolean>,
  dueIn: FormControl<string>,
  selectedTier: FormControl<number>
}

type planTemplateOption = {
  name: string;
  value: PlanTemplate;
  isSelected: boolean;
}

type tierOption = {
  name: string;
  value: number;
  isSelected: boolean;
}

@Component({
  selector: 'app-create-learning-plan',
  templateUrl: './create-learning-plan.component.html'
})
export class CreateLearningPlanComponent extends BaseSmartComponent implements OnInit {

  @Input()
  set planTemplates(templates: PlanTemplate[]) {
    this.templates = templates;

    this.options = templates?.map(planTemplate => this.mapPlanTemplate(planTemplate));

    this.options?.unshift({
      name: this.translate.instant('LABEL_CUSTOM'),
      value: null,
      isSelected: true
    });

    this.setTiers();
  }

  @Input() teamMember: TeamMember;
  userLocale: string;
  loading = false;
  options: planTemplateOption[];
  templates: PlanTemplate[];
  hasError: boolean = false;
  errorMessage: string;

  tiers: tierOption[];
  currentTierID: number = 0;

  createLearningPlanForm: FormGroup<createLearningPlanForm>;

  constructor(protected store: Store<AppState>,
    private fb: FormBuilder,
    private teamLearningPlanSvc: TeamLearningPlanService,
    private router: Router,
    private translate: TranslateService) { super(store); }

  ngOnInit(): void {
    this.$userLocale.pipe(takeUntil(this.ngUnsubscribe)).subscribe(locale => {
      this.userLocale = locale.locale;
    });

    this.setUpForm();
  }

  setUpForm() {
    this.createLearningPlanForm = this.fb.group({
      selectedTemplate: this.fb.control(null),
      name: this.fb.control('', Validators.required),
      assignToAllMembers: this.fb.control(null),
      dueIn: this.fb.control(null),
      selectedTier: this.fb.control(null)
    });

    this.createLearningPlanForm.get('selectedTier').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (selectedTier) => {
        if (selectedTier == this.currentTierID) return;
        this.currentTierID = selectedTier ?? 0;
        this.filterPlans();
      }
    });
  }

  setTiers() {
    if (!this.templates) return;

    // this converts templates[].tiers[] to a single array of tiers
    //
    // This will flatten the child tiers array from the templates array and remove duplicates
    // note: wanted to us flatMap for this we're not on ES2019 yet :(
    const distinctTiers = Array.from(
      new Set(
        this.templates.reduce(
          (carry, current) => [...carry, ...current.tiers],
          []
        )
      )
    );

    // Map to tierOptions, translate tier name and sort alphabetically
    this.tiers = distinctTiers.map(tier => ({
      name: this.translate.instant(`TIER_${tier}`),
      value: tier,
      isSelected: false
    })).sort((a, b) => a.name.localeCompare(b.name));

    // Add "All" option to beginning of sorted list
    this.tiers?.unshift({
      name: this.translate.instant('LABEL_ALL'),
      value: 0,
      isSelected: true
    });
  }

  filterPlans() {
    this.options = this.templates?.filter(x => this.currentTierID == 0 || x.tiers?.indexOf(this.currentTierID) >= 0).map(planTemplate => this.mapPlanTemplate(planTemplate));

    this.options?.unshift({
      name: this.translate.instant('LABEL_CUSTOM'),
      value: null,
      isSelected: true
    });
  }

  mapPlanTemplate(template: PlanTemplate): planTemplateOption {
    return {
      value: template,
      name: template.name,
      isSelected: false
    }
  }

  get f() {
    return this.createLearningPlanForm?.controls;
  }

  onSelectedTemplate($event) {
    this.f.name.setValue($event?.name ?? '');
  }

  createGroupLearningPlan() {
    if (!this.createLearningPlanForm || this.createLearningPlanForm.invalid) {
      return;
    }

    this.loading = true;
    this.hasError = false;
    this.errorMessage = null;
    let template: PlanTemplate;

    if (!this.f.selectedTemplate.value) {
      template = {
        id: '',
        name: '',
        locked: false
      }
    } else {
      template = this.f.selectedTemplate.value;
    }

    const planDetails: PlanDetailsDto = {
      isAutomaticallyAssigned: this.f.assignToAllMembers.value,
      name: this.f.name.value,
      template: template,
      dueInDays: this.f.dueIn.value
    }

    this.teamLearningPlanSvc.createLearningPlan(this.teamMember.team.teamID, planDetails).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (planId) => {
        if (planDetails.template.locked) {
          this.router.navigate([this.userLocale, 'groups', this.teamMember?.team?.friendlyURL, 'plans', planId]);
        } else {
          this.router.navigate([this.userLocale, 'groups', this.teamMember?.team?.friendlyURL, 'plans', planId, 'objects']);
        }
      },
      error: (err) => {
        this.loading = false;
        this.hasError = true;
        this.errorMessage = err.Message;
        return of(null);
      }
    })
  }

}
