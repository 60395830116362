<a class="virtual-library-card" (click)="goToFile()" href="#" appPreventDefault>
    <div class="card-item" [class.unset]="!file?.isReady">
        <h5 class="card-title" [innerHTML]="file?.title">
        </h5>
        <span *ngIf="!file?.isReady" class="tag">
            {{'PROCESSING_BUTTON'|translate}}
        </span>
        <uimc-custom-dropdown *ngIf="file?.isReady" #virtualLibCard class="trascript-file-ellipsis">
            <div slot="content">
                <span class="icon-dots-ellipsis-vertical"></span>
            </div>
            <uimc-menu *ngIf="file?.ownerActions?.length > 0">
                <uimc-menu-item *ngFor="let action of file?.ownerActions"
                    (click)="fileAction(file, action, $event, virtualLibCard)">
                    {{action.text | translate}}
                </uimc-menu-item>
            </uimc-menu>
            <uimc-menu *ngIf="file?.memberActions?.length > 0">
                <uimc-menu-item *ngFor="let action of file?.memberActions"
                    (click)="fileAction(file, action, $event, virtualLibCard)">
                    {{action.text | translate}}
                </uimc-menu-item>
            </uimc-menu>
        </uimc-custom-dropdown>
    </div>
</a>

<!-- Add to a Learning Plan Form -->
<div *ngIf="showAddPlan" class="addPlan-form">
    <form id="addFileToPlanForm" [formGroup]="addFileToPlanForm" (ngSubmit)="doAddPlan()">
        <app-form-group>
            <uimc-filtered-select-v2 id="team-virtual-library-item" 
                formControlName="selectedPlan">
                [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate"
                [label]="'TITLE_GROUPLEARNINGPLANS' | translate"
                [filterThreshold]="4">
                <option *ngFor="let option of options" [value]="option.value">{{option.name}}</option></uimc-filtered-select-v2>
        </app-form-group>
        <div *ngIf="isCreatingPlan()" class="margin-top-single margin-top-bottom-single name-box">
            <label class="margin-right-half" for="name">{{'LABEL_NAME' | translate}}</label>
            <input type="text" class="textarea__input" id="name" name="name" formControlName="name"
                placeholder="{{'REQUIRED_FIELD' | translate}}">
            <app-validation-message [control]="addFileToPlanForm" [showOnSubmitted]="true" error="required"
                message="REQUIRED_FIELD"></app-validation-message>
        </div>
        <uimc-checkbox class="margin-top-single margin-top-bottom-single" *ngIf="isCreatingPlan()"
            (checkedChange)="onSelectAllMembers($event)">
            <small><strong>{{'LABEL_AUTOASSIGN' | translate}}</strong></small>
        </uimc-checkbox>
        <button class="button button--primary margin-top-single margin-top-bottom-single">
            {{ (isCreatingPlan() ? 'LABEL_CREATE' : 'ADD_TO_PLAN_BUTTON') | translate}}
        </button>
    </form>
</div>
