import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from "@ngx-translate/core";
import { TeamTransferCreditsDTO } from '@pepconnect/features/teams/dtos/team-transfer-credits.dto';
import { CreditBucket } from '@pepconnect/features/teams/models/team-credit-bucket.model';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { BaseTeamsComponent } from '@pepconnect/features/teams/teams-shared/base/base-teams.component';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { NotificationService } from "@pepconnect/services/notification.service";
import { UimcSingleSelectItems } from '@pepconnect/shared/presentation/forms/form-models/single-select-item.model';
import { ControlsOf } from '@pepconnect/shared/types/controls-of';
import { selectUserLocaleCode } from '@pepconnect/state/auth/auth.selector';
import { AppState } from '@pepconnect/state/index';
import { forkJoin, take, takeUntil } from 'rxjs';

type TransferCreditsForm = ControlsOf<TeamTransferCreditsDTO>

@Component({
  selector: 'app-team-transfer-points',
  templateUrl: './team-transfer-points.component.html',
  styleUrls: ['./team-transfer-points.component.scss']
})
export class TeamTransferPointsComponent extends BaseTeamsComponent implements OnInit {
  @Output() onSuccess = new EventEmitter();

  transferCreditsForm: FormGroup<TransferCreditsForm>;

  readonly userLocaleCode$ = this.store.select(selectUserLocaleCode);

  transferBuckets: CreditBucket[];
  creditTransferTeams: TeamMember[];
  transferOptions: UimcSingleSelectItems;
  destinationOptions: UimcSingleSelectItems;
  userLocale: string;
  processing: boolean = false;
  errorMessage: string;
  selectedDestination: string;


  constructor(
    private teamStore: TeamStore,
    protected store: Store<AppState>,
    private fb: FormBuilder,
    private teamsService: TeamsService,
    private translate: TranslateService,
    private notificationService: NotificationService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.teamStore.$teamMember
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((teamMember) => {
        this.teamMember = teamMember;

        if (this.teamMember?.isMemberActive && this.teamMember?.isAdmin) {
          this.setupForm();
        }
        else {
          this.errorMessage = "Must be active Group owner to transfer points"
        }
      });
  }

  setupForm() {
    forkJoin([
      this.userLocaleCode$.pipe(take(1)),
      this.teamsService.fetchTeamCredits(this.teamMember?.team?.teamID).pipe(take(1)),
      this.teamsService.fetchTeams().pipe(take(1))])
      .subscribe(([userLocale, creditBuckets, teams]) => {

        // if the form has already been built, reset the values
        if (this.transferCreditsForm) {
          this.reset();
        }

        this.userLocale = userLocale;

        this.transferBuckets = creditBuckets
          .filter((b) => b.available && b.credits > b.creditsUsed);

        this.transferOptions = this.transferBuckets.map((b) => ({
          value: b.id,
          name: this.getBucketDisplayName(b),
          isSelected: false
        }));

        this.creditTransferTeams = teams
          .filter((t) => t.isAdmin && (t.team?.teamID !== this.teamMember?.team?.teamID));

        this.destinationOptions = this.creditTransferTeams.map((t) => ({
          value: t.team.teamID,
          name: t.team.name,
          isSelected: false
        }));

        // create form if it doesn't already exist
        if (!this.transferCreditsForm) {
          this.transferCreditsForm = new FormGroup({
            sourceBucketId: this.fb.control('', Validators.required),
            creditsAmount: this.fb.control(null, { validators: [Validators.required] }),
            destinationTeamId: this.fb.control('', Validators.required)
          });
        }

      });
  }

  setCreditAmountValidators() {
    // get the current selection for source buckets
    const selectedBucket = this.transferBuckets.find(b => b.id === this.f.sourceBucketId.value);

    this.transferCreditsForm.controls.creditsAmount.setValidators([Validators.required]);
    if (selectedBucket) {
      this.transferCreditsForm.controls.creditsAmount.setValidators([Validators.min(1), Validators.max(selectedBucket.creditsAvailable), Validators.required]);
    }
    this.transferCreditsForm.controls.creditsAmount.updateValueAndValidity();
  }

  get f() {
    return this.transferCreditsForm.controls;
  }

  reset() {
    this.transferCreditsForm.reset();
    this.setCreditAmountValidators();
  }

  onSourceBucketChanged() {
    this.setCreditAmountValidators();
  }

  onSelectedItem($event) {
    this.selectedDestination = $event;
    this.transferCreditsForm.controls.destinationTeamId.setValue(this.selectedDestination);
  }

  onSubmit() {
    this.transferCreditsForm.markAllAsTouched();

    if (this.transferCreditsForm.invalid) {
      return;
    }

    this.processing = true;
    this.errorMessage = null;

    this.teamsService
      .transferCredits(this.teamMember.team.teamID, this.transferCreditsForm.getRawValue())
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.processing = false;
          const newCreditCount = this.teamMember.team.creditCount - this.transferCreditsForm.controls.creditsAmount.value;
          this.teamStore.setTeamMemberCreditCount(newCreditCount);
          this.notificationService.successMessage(this.translate.instant(res?.message ?? 'Success'));
          this.onSuccess.emit();

          this.transferCreditsForm.markAsUntouched();
        },
        error: (err: UnserializedResponseMessage) => {
          this.processing = false;
        }
      });
  }

  getBucketDisplayName(bucket: CreditBucket): string {
    return `${formatDate(bucket.expirationDate, 'longDate', this.userLocale)} (${bucket.creditsAvailable})`
  }
}
