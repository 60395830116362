<ng-container *ngIf="teamMember">
    <app-collection>
        <app-collection-item>
            <app-collection-item-header>{{ 'TITLE_GROUPLEARNINGPLANS' | translate }}</app-collection-item-header>
        </app-collection-item>
        <ng-container *ngIf="teamPlans?.length > 0">
            <app-team-group-learning-plan-item *ngFor="let plan of teamPlans"
                [learningPlan]="plan"></app-team-group-learning-plan-item>
        </ng-container>
        <app-collection-item class="clickable-collection-item" *ngIf="teamPlans?.length > 0"
            (click)="printPlanProgressReport()">
            <span class="icon-color icon-print"></span>
            <span class="margin-left-half">{{'LINK_LEARNINGPLANPROGRESSREPORT' | translate }}</span>
        </app-collection-item>
        <app-collection-item class="clickable-collection-item" (click)="printGroupActivity()">
            <span class="icon-color icon-print"></span>
            <span class="margin-left-half">{{'TITLE_GROUP_ACTIVITY' | translate }}</span>
        </app-collection-item>
        <app-collection-item-collapsible (click)="onClick($event)">
            <app-collection-collapse-child class="clickable-collection-item">
                <span class="icon-color icon-plus"></span>
                <span class="margin-left-half">{{'LINK_CREATELEARNINGPLAN' | translate }}</span>
            </app-collection-collapse-child>
            <!-- Add to a Learning Plan Form -->
            <app-collection-collapse-child>
                <app-create-learning-plan [planTemplates]="planTemplates" [teamMember]="teamMember"></app-create-learning-plan>
            </app-collection-collapse-child>
        </app-collection-item-collapsible>
    </app-collection>
</ng-container>
