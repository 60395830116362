<!-- NOTE: member refers to the iterated over member. teamMember refers to the logged in user viewing the member-->
<div class="row" *ngIf="member && teamMember" [id]="'member-details-' + member.id">
    <div [ngClass]="{'col-3 padding-left-0': member.selectedPlan, 'col-4': !member.selectedPlan }">
        <app-user-avatar [userAvatar]="memberAvatar" size="medium" [showOwnerBadge]="member.isAdmin"></app-user-avatar>
    </div>
    <div [ngClass]="{'col-6 padding-right-0': member.selectedPlan, 'col-8': !member.selectedPlan }">
        <h5 class="margin-top-0 margin-bottom-0 font-size-small">{{ memberName }}</h5>
        <ul *ngIf="!member.selectedPlan" class="list list--block overflow-break-word">
            <li class="list__item font-size-xsmall">{{ member.statusText | translate }}</li>
            <li class="list__item font-size-xsmall color-black-60" teamMember.isActive>{{ memberJoinOrInvitedLabel | translate }} {{ memberJoinOrInvitedDate | localeDate }}</li>
            <li class="list__item font-size-xsmall color-black-60 member-email" *ngIf="teamMember.isAdmin"><a [href]="'mailto:' + memberEmail">{{ memberEmail }}</a></li>
        </ul>
        <ul *ngIf="member.selectedPlan" class="list list--block overflow-break-word">
            <li class="list__item font-size-xsmall">{{ member.statusText | translate }}</li>
            <li class="list__item font-size-xsmall color-black-60">{{ 'TEXT_ASSIGNEDON'| translate }} {{ member.selectedPlan.assignDate | date: "longDate" }}</li>
            <li *ngIf="member.selectedPlan.dueDate" class="list__item font-size-xsmall color-black-60">{{ 'LEARNING_STATUS_DUE'| translate }} {{ member.selectedPlan.dueDate | date: "longDate" }}</li>
        </ul>
    </div>
    <div *ngIf="member.selectedPlan" class="col-3 padding-left-0 member-plan-card">
        <app-plans-pie-chart [status]="member.selectedPlan.status"></app-plans-pie-chart>
    </div>
</div>
