import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { Link } from '@pepconnect/models/general/link.model';
import { AppState } from '@pepconnect/state/index';
import { BaseTeamMemberComponent } from '@pepconnect/teams/teams-shared/base/base-team-member.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss']
})
export class MemberDetailsComponent extends BaseTeamMemberComponent implements OnInit {
  @Input() member: TeamMember;
  /** Optional - Can be set via TeamStore */
  @Input() teamMember: TeamMember;
  @Input() showActionsMenu = true;

  actions: Array<Link>;
  constructor(
    protected store: Store<AppState>,
    private teamStore: TeamStore,
    ) { super(store) }

  ngOnInit(): void {
    if(!this.teamMember) {
      this.setTeamMemberFromStore();
    }
  }

  setTeamMemberFromStore() {
    this.teamStore.$teamMember.pipe(takeUntil(this.ngUnsubscribe)).subscribe(tm => {
      this.teamMember = tm;
    });
  }

}
