<div class="sub-action-section margin-bottom-single" *ngIf="showAction(teamSubscription)" [class.no-sub-accesses]="!showPurchaseForm()">
    <ng-container
        *ngIf="showPurchaseForm()">
        <div style="display:inline-block;">
        <label><strong>{{ 'LABEL_SUBSCRIPTION_ACCESSES' | translate }}</strong></label>
        <form [formGroup]="purchaseQuantityForm" (click)="$event.stopPropagation()"
            (ngSubmit)="purchaseQuantityForm.valid">
            <input class="sub-number-selector show-spin-buttons" id="quantity" name="quantity"
                formControlName="quantity" type="number" value="50" min="1" max="999"
                pattern="[0-9]+" required [placeholder]="'TEXT_SELECT' | translate">
            <small *ngIf="f['quantity'].touched && f['quantity'].invalid"
                class="color-red-dark margin-left-qtr">{{'REQUIRED_FIELD' | translate}}</small>
        </form>
        </div>
    </ng-container>
    <div class="button-stack" *ngFor="let action of teamSubscription?.ownerActions" (click)="$event.stopPropagation()">
        <button (click)="actionCall(teamSubscription, action, $event)"
        [disabled]="disableButton(action)"
            class="button button--primary">
            {{action.text | translate}}
        </button>
    </div>
</div>