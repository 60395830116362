import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { CartService } from '@pepconnect/features/checkout/services/cart.service';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamSubscription } from '@pepconnect/features/teams/models/team-subscription';
import { Team } from '@pepconnect/features/teams/models/team.model';
import { Link } from '@pepconnect/models/general/link.model';
import { LinksService } from '@pepconnect/services/links.service';
import { ModalsService } from '@pepconnect/services/modals.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AppState } from '@pepconnect/state/index';
import { isPopulated } from '@pepconnect/utils/generic-utils';
import { take, of, takeUntil } from 'rxjs';

@Component({
  selector: 'app-subscription-action',
  templateUrl: './subscription-action.component.html',
  styleUrls: ['./subscription-action.component.scss']
})
export class SubscriptionActionComponent extends BaseSmartComponent implements OnInit {

  @Input() teamSubscription: TeamSubscription;
  @Input() teamMember: TeamMember;
  @Input() team: Team;
  purchaseQuantityForm: FormGroup;

  constructor(protected store: Store<AppState>,
    private fb: FormBuilder,
    private router: Router,
    private cartService: CartService,
    private linksService: LinksService,
    private modalsService: ModalsService) { super(store); }

  ngOnInit(): void {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (response) => {
        this.authState = response;
      }
    })
    this.setUpForm();
  }

  setUpForm() {
    this.purchaseQuantityForm = this.fb.group({
      quantity: this.fb.control(null, Validators.required)
    });
  }

  get f() {
    return this.purchaseQuantityForm?.controls;
  }

  disableButton(action: Link): boolean {
    return (action.rel == LinkRel.ownerPurchase && this.teamSubscription?.subscription.licenseType.isPerUserLicense && !this.purchaseQuantityForm.get('quantity') || this.purchaseQuantityForm.get('quantity').value < 1) && this.showPurchaseForm();
  }

  showAction(teamSubs: TeamSubscription): boolean {
    return !(teamSubs?.subscription?.requirePEPConnections && !this.team.hasPEPConnections && !teamSubs?.active && this.team.hasFuturePEPConnections);
  }

  actionCall(teamSubscription: TeamSubscription, action: Link, $event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    switch (action.rel) {
      case LinkRel.enableSubscriptionAccess:
        this.router.navigate([action.href]);
        break;
      case LinkRel.ownerPurchase:
      case LinkRel.ownerRenew:
        this.purchaseSub(teamSubscription);
        break;
      case LinkRel.ownerCreatePlanFromSubscription:
        if (isPopulated(this.teamSubscription?.subscription?.filterTags)) {
          const path = teamSubscription.subscription.links.find(link => link.rel === LinkRel.page)?.href;
          this.router.navigate([path]);
        } else {
          this.addPlan(action);
        }
        break;
    }
  }

  purchaseSub(teamSubscription: TeamSubscription) {
    const orderItem = {
      subscriptionId: teamSubscription.subscription.subscriptionID,
      itemType: 14,
      teamMemberId: this.teamMember.id,
      quantity: this.purchaseQuantityForm.get('quantity').value ? this.purchaseQuantityForm.get('quantity').value : 1
    };

    // initialize checkout with current route
    this.cartService.initialize(orderItem, this.router.url);

    this.cartService.checkout();
  }

  addPlan(action: Link) {
    this.linksService.postAction<string>(action, true).pipe(take(1)).subscribe({
      next: (planId: string) => {
        this.router.navigate([this.authState?.user?.locale?.locale, 'groups', this.teamMember?.team?.friendlyURL, 'plans', planId, 'objects']);
      },
      error: () => {
        return of(null);
      }
    })
  }

  showPurchaseForm(): boolean {
    return this.teamSubscription?.subscription?.licenseType?.isPerUserLicense && !this.teamSubscription?.hasSubscription
  }

}
