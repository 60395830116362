import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TeamMemberActionsService } from '@pepconnect/features/teams/services/team-member-actions.service';
import { BaseMemberCardComponent } from '@pepconnect/features/teams/teams-shared/base/base-member-card.component';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { AppState } from '@pepconnect/state/index';

@Component({
  selector: 'app-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss']
})
export class MemberCardComponent extends BaseMemberCardComponent implements OnInit {
  constructor(
    protected store: Store<AppState>,
    protected teamStore: TeamStore,
    protected teamMemberActionService: TeamMemberActionsService
    ) { super(store, teamStore, teamMemberActionService) }
}
