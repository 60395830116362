import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { LearningPlan } from '@pepconnect/models/plan/learning-plan.model';

@Component({
  selector: 'app-team-group-learning-plan-item',
  templateUrl: './team-group-learning-plan-item.component.html'
})
export class TeamGroupLearningPlanItemComponent {
  @Input() learningPlan: LearningPlan;
  loading: boolean = false;

  constructor(private router: Router) { }

  goToPlan(): void {
    this.loading = true;
    this.router.navigate([this.learningPlan.links.find(link => link.rel === LinkRel.page).href]);
  }

  assignedMembers(): number {
    return this.learningPlan?.status?.notStarted + this.learningPlan?.status?.inProgress + this.learningPlan?.status?.completed;
  }

}
