import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { TermTypes } from '@pepconnect/enums/term-types.enum';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { Team } from '@pepconnect/features/teams/models/team.model';
import { TeamsModalsService } from '@pepconnect/features/teams/services/teams-modals.service';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { Link } from '@pepconnect/models/general/link.model';
import { LinksService } from '@pepconnect/services/links.service';
import { AppState } from '@pepconnect/state/index';
import { isPopulated } from '@pepconnect/utils/generic-utils';
import { take } from 'rxjs';
import { BaseTeamsComponent } from '../../base/base-teams.component';

@Component({
  selector: 'app-member-request',
  templateUrl: './member-request.component.html'
})
export class MemberRequestComponent extends BaseTeamsComponent {
  @Input() requestTeam: Team;
  @Output() removeRequest = new EventEmitter();
  constructor(
    protected store: Store<AppState>,
    private teamService: TeamsService,
    private linksService: LinksService,
    private teamsModalService: TeamsModalsService) { super(store); }

  memberSort(team: Team) {
    if(!team || !isPopulated(team.members)) return [];
    return team.members.sort((a,b) => a.user.lastname > b.user.lastname ? 1 : -1);
  }

  memberName(member: TeamMember) {
    return this.teamMemberHelpers.getMemberName(member);
  }

  dispatch(action: Link) {
    if(action.rel === LinkRel.memberRequestAccess) {
      this.teamService.checkEula().pipe(take(1)).subscribe((needsEula) => {
        if(needsEula) {
          this.teamsModalService.definitions.openEulaModal(TermTypes.SpecialTerms).subscribe(() => this.doXhr(action));
        } else {
          this.doXhr(action);
        }
      });
    } else {
      this.doXhr(action);
    }
  }

  doXhr(action: Link) {
    this.linksService.postAction(action, false).pipe(take(1)).subscribe(() => this.removeRequest.emit());
  }

}
