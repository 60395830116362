import { Component, ElementRef, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { Link } from '@pepconnect/models/general/link.model';
import { UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { UserAvatar } from '@pepconnect/models/user/user-avatar.model';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AppState } from '@pepconnect/state/index';
import { take, takeUntil } from 'rxjs';

/** See also TeamSettings component */
@Component({
  selector: 'app-member-join-invite',
  templateUrl: './member-join-invite.component.html',
  styleUrls: ['./member-join-invite.component.scss']
})
export class MemberJoinInviteComponent extends BaseSmartComponent implements OnInit {

  @Input() member: TeamMember;
  @Input() isGroupDetailPage = false;
  @Input() isActivityPage = false;
  @Input() isGroupsPage = false;

  @Output() accepted = new EventEmitter();
  @Output() denied = new EventEmitter();

  @ViewChild('mform') form: ElementRef;
  groupInviteForm: FormGroup;
  hasError = false;
  errorMessage: string;

  constructor(protected store: Store<AppState>,
    private router: Router,
    private fb: FormBuilder,
    private teamService: TeamsService) { super(store); }

  ngOnInit(): void {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (authState) => {
        this.authState = authState;
      }
    })
    this.setUpForm();
  }

  setUpForm() {
    this.groupInviteForm = this.fb.group({
      optInActivities: this.fb.control(null, Validators.required),
      optInPepActivities: this.fb.control(null, Validators.required)
    });
  }

  get f() {
    return this.groupInviteForm?.controls;
  }

  get userAvatar(): UserAvatar {
    return {
      firstname: this.member?.team?.name,
      avatar: this.member?.team?.metadata?.imageURL ? this.member.team.metadata.imageURL : ''
    }
  }

  linkUrl() {
    const pageLink = this.member?.team?.links?.find(link => (link.rel === LinkRel.page))?.href;
    this.router.navigate([pageLink]);
  }

  // use native element to submit the form because the whole card is hyper-linked
  // and default form click handles cause events to propagate upward to the anchor tag
  submitFormWithoutLinkClick($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();

    const form = this.form.nativeElement;
    form.requestSubmit();
  }

  accept($event: Event, member: TeamMember) {
    $event.stopPropagation();
    $event.preventDefault();

    if (!this.groupInviteForm || this.groupInviteForm.invalid) return;

    this.teamService.acceptGroupInvite(member.memberActions.find(action => action.text === 'GROUP_MEMBER_ACCEPTINVITE').href).pipe(take(1))
      .subscribe({
        next: (response) => {


          this.teamService.updateTranscriptVisibility(member.team.teamID, this.groupInviteForm.get('optInActivities').value).pipe(take(1))
            .subscribe({
              next: (_ => { }),
              error: (err) => { this.hasError = true }
            })

          this.teamService.updateUserActivityFeedVisibility(member.team.teamID, this.groupInviteForm.get('optInPepActivities').value).pipe(take(1))
            .subscribe({
              next: (_ => { }),
              error: (err) => { this.hasError = true }
            })



          this.accepted.emit();
        },
        error: (err) => { this.hasError = true }
      })

    // once all the api call completes, route user to respective group page
    if (this.isGroupsPage || this.isGroupDetailPage) {
      const route = this.member.team.links.find(link => link.rel === LinkRel.page).href;
      this.router.navigate([route]);
    }
  }

  deny($event, member: TeamMember) {
    $event.stopPropagation();
    $event.preventDefault();

    if (member.teamMemberStatus === 'MEMBER_STATUS_INVITED') {
      this.teamService.denyGroupInvite(member.memberActions.find(action => action.text === 'GROUP_MEMBER_DENYINVITE').href).pipe(take(1))
        .subscribe({
          next: (_ => {
            this.denied.emit();
          }),
          error: (err: UnserializedResponseMessage) => {
            this.hasError = true;
            this.errorMessage = err.Message;
          }
        })
    } else {
      this.teamService.cancelRequestToJoinGroup(member.memberActions.find(action => action.text === 'GROUP_MEMBER_CANCELREQUEST').href).pipe(take(1))
        .subscribe({
          next: (_ => {
            this.denied.emit();
          }),
          error: (err) => { this.hasError = true }
        })
    }
  }

  shouldBePrimary(action: Link): boolean {
    return (action.rel !== LinkRel.memberDenyInvite);
  }

  showCardContent(): boolean {
    return this.member?.teamMemberStatus === 'MEMBER_STATUS_INVITED' || this.member?.teamMemberStatus === 'MEMBER_STATUS_REQUESTED';
  }

  isMemberInvited(): boolean {
    return this.member?.teamMemberStatus === 'MEMBER_STATUS_INVITED';
  }

  isEULArequired() {
    return this.member?.teamMemberStatus === 'MEMBER_STATUS_INVITED' && !this.member?.links[0]?.requireEULA;
  }

  isTeamMemberStatusActive(): boolean {
    return this.member?.teamMemberStatus === 'MEMBER_STATUS_ACTIVE';
  }

}
