<!-- Still show the following div even if the back button is not shown to keep proper spacing-->
<div class="row margin-top-1 margin-left-half" *ngIf="team">
    <ol class="bare-list breadcrumbs margin-bottom-half" *ngIf="showBackButton">
        <li class="breadcrumbs__item">
            <a class="breadcrumbs__link" [routerLink]="friendlyUrlTree">{{ (navigatedFromPlan ? 'BUTTON_BACKTOPLANPAGE' : 'BUTTON_BACKTOGROUPPAGE') | translate }}</a>
        </li>
        <li class="breadcrumbs__item" *ngIf="isMatotBulkInvite">
            <a  class="breadcrumbs__link" [routerLink]="matotLink">{{ 'BUTTON_BACKTOEVENTPAGE' | translate }}</a>
        </li>
        <li class="breadcrumbs__item" *ngIf="breadcrumb">
            <a  class="breadcrumbs__link " routerLink="..">{{breadcrumb | translate }}</a>
        </li>
    </ol>
</div>
<div class="row" *ngIf="team">
    <div class="col-12 display-flex flex-md-row flex-column align-items-center text-md-left text-center">
        <app-user-avatar [userAvatar]="teamAvatar" [size]="avatarSize"></app-user-avatar>
        <div class="display-flex flex-column align-items-left">
            <h1 class="margin-left-1" [ngClass]="h1Classes" [class.margin-bottom-half]="!subtitle">
                {{ team.name }}
                <span *ngIf="teamMember?.isAdmin">
                    &lpar;{{ 'TEXT_OWNER' | translate }}&rpar;
                    <a [routerLink]="detailLink" *ngIf="showEditIcon"><span class="icon-edit color-black-40 edit-link" [ngClass]="subtitleClasses"></span></a>
                </span>
                <span *ngIf="subtitle" class="display-block margin-top-0 color-black-90" [ngClass]="subtitleClasses">
                    {{ subtitle | translate }}
                </span>
                <span *ngIf="showStatus && isActive" class="display-block margin-top-0 color-black-60 font-weight-normal font-family-text" [ngClass]="subtitleClasses">
                    {{ 'TEXT_LASTACTIVITY' | translate}} {{ teamMember.team.lastActivity | localeDate: "longDate" }}
                </span>
                <span *ngIf="showStatus && isInvited" class="display-block margin-top-0 color-black-60 font-weight-normal font-family-text" [ngClass]="subtitleClasses">
                    {{ 'TEXT_INVITEDON' | translate}}: {{ teamMember?.inviteDate | date: 'longDate'}}
                </span>
                <span *ngIf="showStatus && isRequested" class="display-block margin-top-0 color-black-60 font-weight-normal font-family-text" [ngClass]="subtitleClasses">
                    {{ 'TEXT_REQUESTEDON' | translate}} {{ teamMember.inviteDate | date: "longDate"}}
                </span>
            </h1>
        </div>
    </div>
</div>
