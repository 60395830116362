import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamLearningPlanService } from '@pepconnect/features/teams/services/team-learning-plan.service';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { LearningPlan } from '@pepconnect/models/plan/learning-plan.model';
import { PlanTemplate } from '@pepconnect/models/plan/plan-template';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { UimcSingleSelectItems } from '@pepconnect/shared/presentation/forms/form-models/single-select-item.model';
import { AppState } from '@pepconnect/state/index';
import { of, takeUntil } from 'rxjs';

@Component({
  selector: 'app-team-group-learning-plan',
  templateUrl: './team-group-learning-plan.component.html',
})
export class TeamGroupLearningPlanComponent extends BaseSmartComponent implements OnInit {

  @Input() teamMember: TeamMember;
  teamPlans: LearningPlan[];
  planTemplates: PlanTemplate[];
  templateOptions: UimcSingleSelectItems;

  constructor(protected store: Store<AppState>,
    private teamLearningPlanService: TeamLearningPlanService,
    private teamsService: TeamsService) { super(store); }

  ngOnInit(): void {
    this.teamLearningPlanService.fetchTeamPlans(this.teamMember.team.teamID).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (plans) => {
        this.teamPlans = plans;
      }
    });

    this.teamLearningPlanService.fetchPlanTemplates(this.teamMember?.team?.teamID).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (templates) => {
        this.planTemplates = templates;
      },
      error: (err) => {
        return of(null);
      }
    });
  }

  onClick(event) {
    // This is to prevent the focus event from accessiBe(acsb) bubbling up to `<div tabindex="-1" data-acsb="focuser" class="acsb-body-focuser" data-acsb-clear-focus-style="true" data-acsb-force-visible="true"></div>` element, on the first click after the page loads.
    event.stopPropagation();
   }

  printPlanProgressReport() {
    this.teamsService.printTeamPlanProgressReport(this.teamMember.team.teamID);
  }

  printGroupActivity() {
    this.teamsService.printGroupActivity(this.teamMember.team.links.find(link => link.rel === LinkRel.self)?.href);
  }

}
