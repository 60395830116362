import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TeamLearningPlanService } from '@pepconnect/features/teams/services/team-learning-plan.service';
import { AssignablePlan } from '@pepconnect/models/plan/assignable-plan.model';
import { BaseComponent } from '@pepconnect/shared/base/base-component/base.component';
import { UimcSingleSelectItems } from '@pepconnect/shared/presentation/forms/form-models/single-select-item.model';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-assignable-plan-selector',
  templateUrl: './assignable-plan-selector.component.html'
})
export class AssignablePlanSelectorComponent extends BaseComponent implements OnInit {

  @Input() teamId: string;
  @Input() memberId: string;
  @Input() control: FormControl;
  @Input() $refresh: Subject<boolean>;

  assignablePlans: AssignablePlan[];
  options: UimcSingleSelectItems;
  selectedItem: string;

  constructor(private teamLearningPlanService: TeamLearningPlanService) { super(); }

  ngOnInit(): void {
    this.fetchAssignablePlans();
    this.watchRefresh();
  }

  watchRefresh() {
    this.$refresh?.pipe(takeUntil(this.ngUnsubscribe))?.subscribe(() => this.fetchAssignablePlans());
  }

  onSelectedEventType($event) {
    this.selectedItem = $event;
    this.control.setValue(this.options.find(i => i.value === $event)?.value);
  }

  fetchAssignablePlans() {
    this.teamLearningPlanService.fetchAssignablePlans(this.teamId, this.memberId)
      .pipe(take(1)).subscribe(plans => {
        this.options = plans.map(p => ({ value: p.id, name: p.name, isSelected: false }));
      });
  }

}
