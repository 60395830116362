<div class="points-picker-section">
    <p *ngIf="!team?.country?.eCommerceEnabled">{{'TEXT_PURCHASEHOWTO' | translate}}</p>
    <strong>{{"LABEL_AMOUNT" | translate}}</strong>
    <!-- pointPickerForm -->
    <form [formGroup]="pointPickerForm" (ngSubmit)="checkout()" id="purchase-points-form">
        <div class="point-buttons">
            <button type="button" *ngFor="let option of pointOptions" class="button"
                [class.selected]="f.selectedPoints.value === option.value" (click)="selectPoints(option.value)">
                {{(option.label || option.value.toString()) | translate}}
            </button>
        </div>

        <!-- input number field for manually entering purchasing points -->
        <div *ngIf="f.selectedPoints.value === -1">
            <label>
                <div style="width:100%;" class="margin-top-single margin-bottom-half"><strong>
                        {{'TEXT_OTHERAMOUNT' | translate}}</strong></div>
                <input class="input-number-selector show-spin-buttons" type="number" min="1" max="5000"
                    formControlName="actualPoints" [placeholder]="'PLACEHOLDER_OTHERPOINTS' | translate">
                <div class="color-red-dark" *ngIf="showInputFieldError()">
                    <small>{{'FIELD_CHECK_POINTSAMOUNT' | translate}}</small>
                </div>
            </label>
        </div>
        <div>
            <small class="color-red-dark" *ngIf="pointPickerForm.touched && f.actualPoints.hasError('required')">
                {{'TEXT_NO_POINTS' | translate}}</small>
        </div>
        <p class="margin-top-single" *ngIf="showCostLabels()">
            <strong>{{'LABEL_TOTALCOST' | translate}} {{formattedCurrency | async}} </strong>
            <br />
            <em>{{'TEXT_PURCHASE_WARNING' | translate}}</em>
        </p>

        <!-- submit/cancel buttons -->
        <div class="margin-top-bottom-single">
            <button class="button button--primary">{{'LABEL_CHECKOUT' | translate}}</button>
            <button type="button" class="button margin-left-half" (click)="cancel()">
                {{'BUTTON_CANCEL' | translate}}</button>
        </div>
    </form>
</div>