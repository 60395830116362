/**
 * Describes the different id's for the event steps.
 * The numeric values should match up to the steps returned from the API
 */
export enum MatotEventSteps {
  None,
  Details = 1,
  Assignment = 2,
  Plan = 2, // alias for assignment
  Invite = 3,
  Review = 4,
  Certificates = 4, // alias for review
}

export enum MatotEventChildStep {
  Objects = 'objects',
  Bulk = 'Bulk',
  Create = 'Create',
  Update = 'Update'
}
