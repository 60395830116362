<div class="sub-card" [id]="'team-sub' + teamSubscription?.subscription?.subscriptionID"
    *ngIf="showActiveSubSection(); else expiredSubs">
    <a (click)="linkUrl()">
        <div class="card-image">
            <app-team-avatar [teamAvatar]="teamSubAvatar" size="small"
                [isAvatarSquare]="shouldAvatarBeCircle()"></app-team-avatar>
        </div>
        <div class="card-content">
            <h5 class="card-title" [innerHTML]="teamSubscription?.subscription?.name">
            </h5>
            <small *ngIf="teamSubscription?.isExpired && teamSubscription?.isStarted">{{'TEXT_EXPIREDON' | translate}}
                {{ teamSubscription?.expires | date: 'medium'}}</small>
            <small *ngIf="!teamSubscription?.isStarted">{{'TEXT_STARTSON' | translate}}
                {{ teamSubscription?.startDate | date: 'medium'}}</small>
            <ng-container
                *ngIf="(!teamSubscription?.isExpired && teamSubscription?.isStarted  && !teamSubscription?.subscription?.licenseType?.isPerUserLicense) || isSubscriptionBrowsePage">
                <small *ngIf="!teamSubscription?.expires">
                    {{ 'TEXT_EXPIRESNEVER' | translate}}</small>
                <small *ngIf="teamSubscription?.expires">
                    {{'TEXT_EXPIRESON' | translate }} {{ teamSubscription?.expires | date: 'medium'}}
                </small>
            </ng-container>
            <ng-container
                *ngIf="teamSubscription?.subscription?.licenseType?.isPerUserLicense && teamMember?.isAdmin && !isSubscriptionBrowsePage">
                <small>{{ 'LABEL_MEMBER_ACCESSES_PURCHASED' | translate }}: {{ teamSubscription?.licenseCount }}</small>
                <small>{{ 'LABEL_MEMBER_ACCESSES_GRANTED' | translate }}: {{ teamSubscription?.assignedLicenseCount }}
                </small>
            </ng-container>
            <div class="tool-tip" *ngIf="teamSubscription?.subscription?.showDisclaimer && !isSubscriptionBrowsePage">
                <uimc-tooltip [attr.content]="teamSubscription?.subscription?.disclaimerText | translate">
                    <span class="icon-exclamationmark"></span>{{'LABEL_VIEW_DISCLAIMER' | translate }}
                </uimc-tooltip>
            </div>
            <div class="sub-action-button" *ngIf="teamSubscription?.ownerActions?.length > 0 && !isSubscriptionBrowsePage">
                <ng-container *ngFor="let action of teamSubscription?.ownerActions">
                    <button (click)="dispatch(action, $event)" class="button button--primary" type="button">
                        {{action.text | translate}}
                    </button>
                </ng-container>
            </div>
        </div>
    </a>
</div>
<div class="sub-card" [id]="'team-sub' + teamSubscription?.subscription?.subscriptionID"
    *ngIf="isUpcomingSubSection">
    <a (click)="linkUrl()">
        <div class="card-image">
            <app-team-avatar [teamAvatar]="teamSubAvatar" size="small"
                [isAvatarSquare]="shouldAvatarBeCircle()"></app-team-avatar>
        </div>
        <div class="card-content">
            <h5 class="card-title" [innerHTML]="teamSubscription?.subscription?.name">
            </h5>
            <small *ngIf="!teamSubscription?.isStarted"><em>
                    {{ 'TEXT_STARTSON' | translate}} {{ teamSubscription?.startDate | date: "medium"}}
                </em>
            </small>
        </div>
    </a>
</div>

<ng-template #expiredSubs>
    <div *ngIf="isExpiredSubSection && !isUpcomingSubSection" class="sub-card" [id]="'team-sub' + teamSubscription?.subscription?.subscriptionID">
        <a (click)="linkUrl()">
            <div class="card-image">
                <app-team-avatar [teamAvatar]="teamSubAvatar" size="small"
                    [isAvatarSquare]="shouldAvatarBeCircle()"></app-team-avatar>
            </div>
            <div class="card-content">
                <h5 class="card-title" [innerHTML]="teamSubscription?.subscription?.name">
                </h5>
                <small *ngIf="teamSubscription?.isExpired && teamSubscription?.isStarted">{{'TEXT_EXPIREDON' |
                    translate}}
                    {{ teamSubscription?.expires | date: 'medium'}}</small>

                <div class="sub-action-button" *ngIf="teamSubscription?.ownerActions?.length > 0">
                    <ng-container *ngFor="let action of teamSubscription?.ownerActions">
                        <button (click)="dispatch(action, $event)" class="button button--primary">
                            {{action.text | translate}}
                        </button>
                    </ng-container>
                </div>
            </div>
        </a>
    </div>
</ng-template>
