import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { UserLearningPlan } from '@pepconnect/models/plan/user-learning-plan.model';
import { AppState } from '@pepconnect/state/index';
import { combineLatest, takeUntil } from 'rxjs';
import { BaseTeamMemberComponent } from '../../base/base-team-member.component';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';

@Component({
  selector: 'app-member-plan-assignments',
  templateUrl: './member-plan-assignments.component.html',
  styleUrls: ['./member-plan-assignments.component.scss']
})
export class MemberPlanAssignmentsComponent extends BaseTeamMemberComponent implements OnInit {
  teamMember: TeamMember;
  member: TeamMember;

  constructor(protected store: Store<AppState>, private teamStore: TeamStore) { super(store) }

  ngOnInit(): void {
    combineLatest([this.teamStore.$teamMember, this.teamStore.$selectedMember])
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(([teamMember, selectedMember]) =>  {
      this.teamMember = teamMember;
      this.member = selectedMember;
    });
  }

  refetchMember() {
    this.teamStore.loadSelectedTeamMember(this.member.id);
  }

  planTrackBy(index, item: UserLearningPlan) {
    return item.teamMemberLearningPlanID;
  }

}
